import React, { Suspense, useContext, useEffect, useState } from 'react';

import classNames from 'classnames';
import Logo from 'components/Logo/Logo';
import NavMenu from 'components/NavMenu';
import UserContext from 'contexts/UserContext';
import { useRouter } from 'next/router';
import OnboardingModal from 'project/Dashboard/OnboardingModal/OnboardingModal';
import { useDebouncedCallback } from 'use-debounce';

import styles from './Layout.module.scss';

const Layout = ({
  children,
  hideMenu = false,
}: {
  hideMenu?: boolean;
  children: JSX.Element | (JSX.Element | null | false | undefined)[];
}): JSX.Element => {
  const router = useRouter();

  const {
    user: { id, isOnboarded },
  } = useContext(UserContext);

  const { matches: initialMatch } = window.matchMedia('(max-width: 991px)');
  const [isMobile, setIsMobile] = useState(initialMatch);
  const getWindowWidth = useDebouncedCallback(() => {
    const { matches } = window.matchMedia('(max-width: 991px)');
    setIsMobile(matches);
  }, 20);

  useEffect(() => {
    window.addEventListener('resize', getWindowWidth);
    return () => {
      window.removeEventListener('resize', getWindowWidth);
    };
  }, []);

  return (
    <>
      {router.asPath === '/' && id && !isOnboarded && <OnboardingModal />}
      {!hideMenu && (
        <Suspense
          fallback={
            <div className="container">
              <Logo className={styles.loadingLogo} />
            </div>
          }
        >
          <NavMenu displayMobile={isMobile} />
        </Suspense>
      )}
      <main className="d-flex">
        <div
          className={classNames('container-fluid', styles.mainLayoutContent)}
        >
          {[children].flat().filter(Boolean)}
        </div>
      </main>
      {router.asPath === '/' && id && !isOnboarded && <OnboardingModal />}
    </>
  );
};

export default Layout;
