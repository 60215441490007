import { CancelTokenCallback } from 'services/useSearchHook';

// eslint-disable-next-line import/no-cycle
import HTTPKit from '../HTTPKit';

interface ServiceListRequest {
  page?: number;
  title?: string;
  categories?: number[] | null;
  status?: number;
}

const ServicesEndpoints = {
  getDetail: (id: number) => {
    const url = `/api/v1/services/${id}/`;
    return HTTPKit.get(url);
  },
  getList: (
    queryOptions?: ServiceListRequest,
    cancelTokenCallback?: CancelTokenCallback,
  ) => {
    const url = '/api/v1/services/';
    return HTTPKit.get(
      url,
      queryOptions,
      cancelTokenCallback &&
        HTTPKit.axiosCancelTokenAsigner(cancelTokenCallback),
    );
  },
  create: (formData: FormData) => {
    const url = '/api/v1/services/';
    return HTTPKit.post(url, formData);
  },
  update: (id: number, formData: FormData) => {
    const url = `/api/v1/services/${id}/`;
    return HTTPKit.patch(url, formData);
  },
  deleteService: (id: number) => {
    const url = `/api/v1/services/${id}/`;
    return HTTPKit.delete(url, {});
  },
  destroyFile: (id: number) => {
    const url = `/api/v1/service-files/${id}/`;
    return HTTPKit.delete(url, {});
  },
  updateFile: (id: number, formData: FormData) => {
    const url = `/api/v1/service-files/${id}/`;
    return HTTPKit.patch(url, formData);
  },
};

export default ServicesEndpoints;
