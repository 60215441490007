const DirectAds = {
  list: {
    as: '/directads',
    href: '/directads',
  },
  detail: (id: number) => ({
    as: `/directads/${id}`,
    href: '/directads/[id]',
  }),
};
export default DirectAds;
