import sluggify from 'services/sluggify';

const Events = {
  list: { as: '/events/upcoming', href: '/events/[id]' },
  past: { as: '/events/past', href: '/events/[id]' },
  detail: (id: number, title?: string) => ({
    as: `/events/${id}${title ? `-${sluggify(title)}` : ''}`,
    href: '/events/[id]',
  }),
  create: { as: '/events/admin', href: '/events/admin' },
  update: (id: number) => ({
    as: `/events/admin/${id}`,
    href: '/events/admin/[id]',
  }),
};
export default Events;
