import HTTPKit from '../HTTPKit';

type GetNotificationsOptions = {
  pageSize?: number;
  page?: number;
  description?: string;
};

export type GetListProps = [url: string, options: GetNotificationsOptions];
export const queries = {
  getCategories: {
    key: '/api/v1/notifications/categories/',
    fetcher: (url: string) => HTTPKit.get(url).then(({ data }) => data),
  },
  getList: {
    key: '/api/v1/notifications/',
    fetcher: async ([url, options]: GetListProps) => {
      const defaultOptions = {
        pageSize: 1,
        page: 1,
      };
      // BE doesn't parse camelCase => snake_case for this q, so parse options.
      let opts: any = Object.assign(defaultOptions, options);
      opts = {
        ...opts,
        page_size: opts.pageSize,
      };
      delete opts.pageSize;

      return HTTPKit.get(url, opts).then(({ data }) => data);
    },
  },
};

type MutateNotificationProps = {
  arg: number;
};
type MutateWithCategoryProps = {
  arg: string | undefined;
};
export const mutations = {
  delete: {
    key: `/api/v1/notifications/`,
    mutation: (url: string, { arg: id }: MutateNotificationProps) =>
      HTTPKit.delete(`${url}${id}/`, {}),
  },
  markAsRead: {
    key: '/api/v1/notifications/',
    mutation: async (url: string, { arg: id }: MutateNotificationProps) => {
      const resp = await HTTPKit.patch(`${url}${id}/`, { unread: false });
      return resp;
    },
  },
  markAllAsRead: {
    key: '/api/v1/notifications/mark-all-as-read/',
    mutation: (url: string, { arg: category }: MutateWithCategoryProps) =>
      HTTPKit.post(url, { description: category }),
  },
  deleteAll: {
    key: '/api/v1/notifications/delete-all/',
    mutation: (url: string, { arg: category }: MutateWithCategoryProps) =>
      HTTPKit.post(url, { description: category }),
  },
};

const SWRNotifications = { mutations, queries };

export default SWRNotifications;
