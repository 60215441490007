import React, { useContext, useState } from 'react';

import UserContext from 'contexts/UserContext';

import ToggleButton from './ToggleButton';
import styles from '../DevControl.module.scss';

const TinyOptions = () => {
  const prototypeClassName = 'obfuscate-content';
  const [prototypeMode, setPrototypeMode] = useState(
    !!document.querySelector(`.${prototypeClassName}`),
  );
  const {
    viewSocialButton,
    toggleViewSocialButton,
    obfuscate,
    toggleObfuscation,
  } = useContext(UserContext);

  const togglePrototypeMode = ({ target: { checked } }): void => {
    const linkId: string = 'prototype-font-link';
    let fontLink: any = document.getElementById(linkId);
    if (checked) {
      if (!fontLink) {
        fontLink = document.createElement('link');
        fontLink.href =
          'https://fonts.googleapis.com/css2?family=Flow+Block&display=swap';
        fontLink.id = linkId;
        fontLink.rel = 'stylesheet';
        document.getElementsByTagName('head')[0].appendChild(fontLink);
      }
      document.body.classList.add(prototypeClassName);
    } else {
      document.body.classList.remove(prototypeClassName);
    }
    setPrototypeMode(checked);
  };

  const options = [
    {
      label: 'Enter Prototype Mode',
      isChecked: prototypeMode,
      handleChange: togglePrototypeMode,
      id: 'prototype-mode',
    },
    {
      label: 'View Social Button',
      isChecked: viewSocialButton,
      handleChange: toggleViewSocialButton,
      id: 'toggle-view-social-buttons',
    },
    {
      label: 'Obfuscate Member Name',
      isChecked: obfuscate,
      handleChange: toggleObfuscation,
      id: 'toggle-obfuscation',
    },
  ];
  return (
    <div className="mb-2">
      <h2 className={styles.h2}>Tiny Options:</h2>
      <ul className={styles.tinyOptionsList}>
        {options.map(itemOpts => (
          <ToggleButton key={itemOpts.id} {...itemOpts} />
        ))}
      </ul>
    </div>
  );
};

export default TinyOptions;
