/* eslint-disable import/no-cycle */
import axios, {
  AxiosRequestHeaders,
  Canceler,
  CancelToken,
  ResponseType,
} from 'axios';

import requestInterceptor from './interceptors/request';
import responseErrorInterceptor from './interceptors/responseError';

class APIHelper {
  client = axios.create({
    baseURL: process.env.API_BASE_URL,
    headers: { 'Content-Type': 'application/json' },
  });

  constructor() {
    this.client.interceptors.request.use(requestInterceptor);
    this.client.interceptors.response.use(
      response => response,
      responseErrorInterceptor,
    );
  }

  // eslint-disable-next-line class-methods-use-this
  axiosCancelTokenAsigner = (callBack: (cancel: Canceler) => void) =>
    new axios.CancelToken(cancel => callBack(cancel));

  setAuthHeader = (accessToken: string) => {
    this.client.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  };

  get = (
    url: string,
    params?: object,
    cancelToken?: CancelToken,
    responseType?: ResponseType,
  ) =>
    this.client({
      method: 'get',
      params: params || {},
      url,
      cancelToken,
      responseType,
    });

  post = (
    url: string,
    data: object,
    params: object = {},
    headers: AxiosRequestHeaders = {},
  ) => this.client({ data, headers, method: 'post', params, url });

  put = (url: string, data: object, params: object = {}) =>
    this.client({ data, method: 'put', params, url });

  patch = (url: string, data: object, params: object = {}) =>
    this.client({ data, method: 'patch', params, url });

  delete = (url: string, data: object) =>
    this.client({ data, method: 'delete', url });
}

export default new APIHelper();
