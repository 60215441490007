import { CancelTokenCallback } from 'services/useSearchHook';

// eslint-disable-next-line import/no-cycle
import HTTPKit from '../HTTPKit';

interface WikiListRequest {
  page?: number;
  title?: string;
  categories?: number[] | null;
  status?: string;
}

const WikiEndpoints = {
  articles: {
    getFilters: () => {
      const url = '/api/v1/wikis/categories/';
      return HTTPKit.get(url);
    },
    getList: (
      queryOptions?: WikiListRequest,
      cancelTokenCallback?: CancelTokenCallback,
    ) => {
      const url = '/api/v1/wikis/';
      return HTTPKit.get(
        url,
        queryOptions,
        cancelTokenCallback &&
          HTTPKit.axiosCancelTokenAsigner(cancelTokenCallback),
      );
    },
    getDetail: (id: number, queryOptions?: WikiListRequest) => {
      const url = `/api/v1/wikis/${id}/`;
      return HTTPKit.get(url, queryOptions);
    },
    create: (formData: FormData) => {
      const url = '/api/v1/wikis/';
      return HTTPKit.post(url, formData);
    },
    update: (id: number, formData: FormData) => {
      const url = `/api/v1/wikis/${id}/`;
      return HTTPKit.patch(url, formData);
    },
    deleteArticle: (id: number) => {
      const url = `/api/v1/wikis/${id}/`;
      return HTTPKit.delete(url, {});
    },
    destroyFile: (id: number) => {
      const url = `/api/v1/wiki-files/${id}/`;
      return HTTPKit.delete(url, {});
    },
    updateFile: (id: number, formData: FormData) => {
      const url = `/api/v1/wiki-files/${id}/`;
      return HTTPKit.patch(url, formData);
    },
  },
  handbooks: {
    getFilters: () => {
      const url = '/api/v1/wiki/handbooks/tags/';
      return HTTPKit.get(url);
    },
    getList: (
      queryOptions?: WikiListRequest,
      cancelTokenCallback?: CancelTokenCallback,
    ) => {
      const url = '/api/v1/wiki/handbooks/';
      return HTTPKit.get(
        url,
        queryOptions,
        cancelTokenCallback &&
          HTTPKit.axiosCancelTokenAsigner(cancelTokenCallback),
      );
    },
    getDetail: (id: number | string) => {
      // TODO February 02, 2021: number|string => number
      const url = `/api/v1/wiki/handbooks/${id}/`;
      return HTTPKit.get(url);
    },
    create: (formData: FormData) => {
      const url = '/api/v1/wiki/handbooks/';
      return HTTPKit.post(url, formData);
    },
    update: (id: number, formData: FormData) => {
      const url = `/api/v1/wiki/handbooks/${id}/`;
      return HTTPKit.patch(url, formData);
    },
    delete: (id: number) => {
      const url = `/api/v1/wiki/handbooks/${id}/`;
      return HTTPKit.delete(url, {});
    },
  },
  chapters: {
    getDetail: (id: number | string) => {
      const url = `/api/v1/wiki/chapters/${id}/`;
      return HTTPKit.get(url);
    },
    create: (formData: FormData) => {
      const url = '/api/v1/wiki/chapters/';
      return HTTPKit.post(url, formData);
    },
    update: (id: number, formData: FormData) => {
      const url = `/api/v1/wiki/chapters/${id}/`;
      return HTTPKit.patch(url, formData);
    },
    delete: (id: number) => {
      const url = `/api/v1/wiki/chapters/${id}/`;
      return HTTPKit.delete(url, {});
    },
  },
  sections: {
    getDetail: (id: number | string) => {
      const url = `/api/v1/wiki/sections/${id}/`;
      return HTTPKit.get(url);
    },
    create: (formData: FormData) => {
      const url = '/api/v1/wiki/sections/';
      return HTTPKit.post(url, formData);
    },
    update: (id: number, formData: FormData) => {
      const url = `/api/v1/wiki/sections/${id}/`;
      return HTTPKit.patch(url, formData);
    },
    delete: (id: number) => {
      const url = `/api/v1/wiki/sections/${id}/`;
      return HTTPKit.delete(url, {});
    },
    destroyFile: (id: number) => {
      const url = `/api/v1/wiki/section-files/${id}/`;
      return HTTPKit.delete(url, {});
    },
    updateFile: (id: number, formData: FormData) => {
      const url = `/api/v1/wiki/section-files/${id}/`;
      return HTTPKit.patch(url, formData);
    },
  },
};
export default WikiEndpoints;
