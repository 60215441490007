import HTTPKit from '../HTTPKit';

export const queries = {
  getLocaleOptions: {
    key: '/api/v1/dev/locale-options/',
    fetcher: url => HTTPKit.get(url).then(({ data }) => data),
  },
};

type MutateLocaleProps = {
  language?: number;
  member?: { country?: number; id?: number };
  role?: number;
};
export const mutations = {
  updateLocale: {
    key: '/api/v1/dev/country-switcher/',
    mutate: (url, payload: MutateLocaleProps) => HTTPKit.patch(url, payload),
  },
};
const SWRLocale = { queries, mutations };

export default SWRLocale;
