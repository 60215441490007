import React from 'react';

import Link from 'next/link';
import { useTranslation } from 'react-i18next';
import sluggify from 'services/sluggify';

import DropdownMenuItem from './DropdownMenuItem';
import styles from '../NavMenu.module.scss';
import type { MenuLinksProps } from '../types';

const MenuLinks = ({ linkList }: MenuLinksProps) => {
  const { t } = useTranslation();
  return (
    <ul className="list-unstyled d-flex m-0 p-0">
      {linkList?.map((menuItem: any) =>
        menuItem.kind === 'DROPDOWN' ? (
          <DropdownMenuItem
            key={sluggify(menuItem.title)}
            menuItem={menuItem}
          />
        ) : (
          <Link
            key={sluggify(menuItem.title)}
            href={menuItem.path.href}
            as={menuItem.path.as}
            className={`btn mx-2  ${styles.dropdown} ${styles.WrapText} text-wrap`}
          >
            {t(menuItem.title)}
          </Link>
        ),
      )}
    </ul>
  );
};

export default MenuLinks;
