// eslint-disable-next-line import/no-cycle
import HTTPKit from '../HTTPKit';

const UsersEndpoints = {
  getMe: () => {
    const url = '/api/v1/me/';
    return HTTPKit.get(url);
  },
  patchMe: (
    payload: Record<string, any>,
    queryParams?: Record<string, any>,
  ) => {
    const url = '/api/v1/me/';
    return HTTPKit.patch(url, payload, queryParams);
  },
  updatePreferences: (payload: Record<string, any>) => {
    const url = '/api/v1/me/preferences/';
    return HTTPKit.post(url, payload);
  },
  getList: (queryOptions?: {
    page?: number;
    role?: boolean;
    staff?: boolean;
    member?: boolean;
  }) => {
    const url = '/api/v1/users/';
    return HTTPKit.get(url, queryOptions);
  },
  inviteUser: (formData: FormData) => {
    const url = '/api/v1/users/';
    return HTTPKit.post(url, formData);
  },
  updateUser: (id: number, payload: Record<string, any>) => {
    const url = `/api/v1/users/${id}/`;
    return HTTPKit.patch(url, payload);
  },
};

export default UsersEndpoints;
