import sluggify from 'services/sluggify';

const Campaigns = {
  list: {
    as: '/campaigns',
    href: '/campaigns',
  },
  detail: (id: number, title?: string) => ({
    as: `/campaigns?id=${id}${title ? `-${sluggify(title)}` : ''}`,
    href: `/campaigns?id=${id}${title ? `-${sluggify(title)}` : ''}`,
  }),
  create: { as: '/campaigns/admin', href: '/campaigns/admin' },
  update: (id: number) => ({
    as: `/campaigns/admin/${id}`,
    href: '/campaigns/admin/[id]',
  }),
};
export default Campaigns;
