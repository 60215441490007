import React, { useEffect, useState } from 'react';

import useMediaQuery from '@mui/material/useMediaQuery';
import CenteredSpinner from 'components/Loading/CenteredSpinner';
import Head from 'next/head';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import { getEdgeData, EdgeDataInterface } from 'services/EdgeConfig';
import RouteKit from 'services/RouteKit';

import Maintenance from './Maintenance';

type ErrorHTTPProps = {
  code: number;
  buttonLink?: string;
  buttonLinkAs?: string;
  hideButton?: boolean;
  message?: string | JSX.Element;
};

const ErrorHTTP = ({
  code,
  buttonLink = RouteKit.index.href,
  buttonLinkAs = RouteKit.index.as,
  hideButton = false,
  message,
}: ErrorHTTPProps): JSX.Element => {
  const bootstrapMD = useMediaQuery('(min-width: 768px)');
  const { t } = useTranslation();
  const router = useRouter();
  const [edgeItems, setEdgeItems] = useState<EdgeDataInterface | null>(null);

  const isInMaintenanceMode =
    edgeItems?.backendDeployInProgress || edgeItems?.frontendDepolyInProgress;

  const getMessageFromStatusCode = (_code: number) => {
    switch (_code) {
      case 404:
        return t(
          'errorPage.notFound',
          'We are sorry, but the page or resource you are looking for was not found',
        );
      case 401:
      case 403:
        return t(
          'errorPage.notAuthorized',
          'You do not have access to the requested page. It might belong to another country.',
        );
      case 500:
        return t(
          'errorPage.internalServerError',
          'We are sorry, but something has gone wrong',
        );
      default:
        return t(
          'errorPage.genericError',
          'We are sorry, but the page or resource you are looking for was not found',
        );
    }
  };
  const getMaintenanceMode = async () => {
    const inMaintenance =
      edgeItems?.backendDeployInProgress || edgeItems?.frontendDepolyInProgress;
    const items = await getEdgeData();
    if (
      inMaintenance &&
      !(items.backendDeployInProgress || items.frontendDepolyInProgress)
    ) {
      // users won't get logged out if the BE server is down, but hte FE app
      // will redirect to login page if the tokens fail.
      // so we need to reload the page to get the user back to the page they were on
      // before the maintenance mode started
      // If the user actually is logged out the app should handle that
      if (router.query.next) {
        await router.push(router.query.next as string);
      }
      router.reload();
    } else {
      setEdgeItems(items);
    }
  };
  useEffect(() => {
    getMaintenanceMode();
    let interval: any;
    if (isInMaintenanceMode) {
      interval = setInterval(() => {
        getMaintenanceMode();
      }, 1000 * 30);
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [isInMaintenanceMode]);

  if (edgeItems === null) {
    return <CenteredSpinner />;
  }

  const headTitle = isInMaintenanceMode
    ? t('errorPage.tabTitle.maintenance', 'Maintenance')
    : t('errorPage.tabTitle.error', 'Error');
  const getButtonText = () =>
    buttonLink === '/'
      ? t('errorPage.buttonLink.home', 'Back to Homepage')
      : t('errorPage.buttonLink.generic', 'Go back');

  return (
    <>
      <Head>
        <title>{headTitle}</title>
      </Head>
      {isInMaintenanceMode ? (
        <Maintenance {...edgeItems} />
      ) : (
        <div className="container">
          <div
            className={`d-flex justify-content-center ${
              bootstrapMD ? 'mt-5' : ''
            }`}
          >
            <img className="mw-100" src="/img/cat.svg" alt="Cat sleeping" />
          </div>
          <div className="row">
            <div className="col-md-0 col-lg-3" />
            <div className="col-md-12 col-lg-6">
              <div className="mt-5">
                {typeof code === 'number' && (
                  <h1 className="text-center">{code && code}</h1>
                )}
                {message && typeof message !== 'string' ? (
                  <div className="text-center">{message}</div>
                ) : (
                  <p className="text-center">
                    {message || getMessageFromStatusCode(code)}
                  </p>
                )}
                {!hideButton && (
                  <Link href={buttonLink} as={buttonLinkAs} passHref>
                    <button
                      type="submit"
                      className="btn btn-primary w-50 d-block mx-auto"
                    >
                      {t('errorPage.backButtonText', getButtonText())}
                    </button>
                  </Link>
                )}
              </div>
            </div>
            <div className="col-md-0 col-lg-3" />
          </div>
        </div>
      )}
    </>
  );
};

export default ErrorHTTP;
