import { createContext } from 'react';

import { User } from 'services/APIKit/types';

export type UserContextType = {
  token: string;
  user: User;
  userLang: string;
  viewSocialButton: boolean;
  obfuscate: boolean;
  refreshUser: () => void;
  toggleViewAsStaff: () => void;
  toggleViewSocialButton: () => void;
  toggleObfuscation: () => void;
};

const defaultUser = {
  id: 0,
  email: '',
  firstName: '',
  lastName: '',
  language: '',
  title: '',
  member: null,
  phoneNumber: '',
  isOnboarded: false,
  isStaff: false,
  isSuperuser: false,
  preferences: [],
  hasDigestSubscribed: false,
  levels: [],
};

const defautlUserContext = {
  token: '',
  user: defaultUser,
  userLang: '',
  viewSocialButton: true,
  obfuscate: false,
  refreshUser: () => {},
  toggleViewAsStaff: () => {},
  toggleViewSocialButton: () => {},
  toggleObfuscation: () => {},
};

const UserContext = createContext<UserContextType>(defautlUserContext);

export default UserContext;
