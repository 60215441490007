import { CancelTokenCallback } from 'services/useSearchHook';

// eslint-disable-next-line import/no-cycle
import HTTPKit from '../HTTPKit';

const EventsEndpoints = {
  getFilters: () => {
    const url = '/api/v1/events/categories/';
    return HTTPKit.get(url);
  },
  getList: (
    queryOptions: {
      page?: number;
      title?: string;
      categories?: string;
      status?: number;
      scope?: 'upcoming' | 'past';
    },
    cancelTokenCallback?: CancelTokenCallback,
  ) => {
    const { page, title, categories, status, scope } = queryOptions;
    let url = '/api/v1/events/';
    if (scope) url = `${url}${scope}/`;
    return HTTPKit.get(
      url,
      {
        page,
        title,
        categories,
        status,
      },
      cancelTokenCallback &&
        HTTPKit.axiosCancelTokenAsigner(cancelTokenCallback),
    );
  },
  getDetail: (id: number) => {
    const url = `/api/v1/events/${id}/`;
    return HTTPKit.get(url);
  },
  createEvent: (formData: FormData) => {
    const url = '/api/v1/events/';
    return HTTPKit.post(url, formData);
  },
  updateEvent: (id: number, formData: FormData) => {
    const url = `/api/v1/events/${id}/`;
    return HTTPKit.patch(url, formData);
  },
  deleteEvent: (id: number) => {
    const url = `/api/v1/events/${id}/`;
    return HTTPKit.delete(url, {});
  },
  updateFile: (id: number, formData: FormData) => {
    const url = `/api/v1/event-files/${id}/`;
    return HTTPKit.patch(url, formData);
  },
  destroyFile: (id: number) => {
    const url = `/api/v1/event-files/${id}/`;
    return HTTPKit.delete(url, {});
  },
};
export default EventsEndpoints;
