import React from 'react';

import SubmitButton from 'components/SubmitButton/SubmitButton';

import styles from './NextStep.module.scss';

interface ButtonAreaProps {
  buttonLabel: string;
  description?: string;
  disabled?: boolean;
  isSubmitting: boolean;
  onSubmit?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const NextStep = ({
  buttonLabel,
  description,
  disabled,
  isSubmitting,
  onSubmit,
}: ButtonAreaProps) => (
  <div className={styles.nextStep}>
    {description && <p className={styles.nextStepDescription}>{description}</p>}
    <SubmitButton
      onSubmit={onSubmit}
      disabled={disabled}
      isSubmitting={isSubmitting}
      text={buttonLabel}
      className={styles.stepButton}
    />
  </div>
);

export default NextStep;
