const vetData = {
  index: {
    as: '/vetdata',
    href: '/vetdata',
  },
  suppliersDashboard: {
    as: '/vetdata/suppliers-dashboard',
    href: '/vetdata/suppliers-dashboard',
  },
  memberReport: {
    as: '/vetdata/member-report',
    href: '/vetdata/member-report',
  },
  memberDashboard: {
    as: '/vetdata/member-dashboard',
    href: '/vetdata/member-dashboard',
  },
  suppliersReports: {
    as: '/vetdata/suppliers-reports',
    href: '/vetdata/suppliers-reports',
  },
  membersReport: {
    as: '/vetdata/members-report',
    href: '/vetdata/members-report',
  },
  list: { as: '/vetdata/surveys', href: '/vetdata/surveys' },
  questions: (themeId: number) => ({
    as: `/vetdata/surveys/${themeId}`,
    href: '/vetdata/surveys/[id]',
  }),
};

export default vetData;
