import React, { useContext } from 'react';

import SelectInput from 'components/FormInputs/SelectInput/SelectInput';
import UserContext from 'contexts/UserContext';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import APIKit from 'services/APIKit';
import { rolesEnum } from 'services/helpers';

import styles from '../DevControl.module.scss';

const UserRoleSelector = () => {
  const { t } = useTranslation();
  const { user, refreshUser } = useContext(UserContext);

  const allRoles = rolesEnum.map(role => ({ id: role.id, name: t(role.name) }));
  const handleChange = async event => {
    const { value } = event.target;
    await APIKit.dev.updateLocale({ role: value });
    refreshUser();
  };
  return (
    <>
      <h2 className={styles.h2}>
        <i className="text-gray-600 fas fa-id-badge mr-1" /> Select User Level:
      </h2>
      <Formik
        initialValues={{ role: user.levels[0].id }}
        onSubmit={v => handleChange(v.role)}
      >
        {(): React.ReactElement => (
          <Form className="accordion d-flex flex-column justify-content-space-between h-100">
            <SelectInput
              name="role"
              className="mb-2 mb-md-3 px-0"
              collection={allRoles}
              updateState={handleChange}
              modal
            />
          </Form>
        )}
      </Formik>
    </>
  );
};

export default UserRoleSelector;
