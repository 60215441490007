import { AxiosRequestConfig } from 'axios';
import { removeTokens, setAuthTokens } from 'services/withAuth';

// eslint-disable-next-line import/no-cycle
import APIKit from '..';
// eslint-disable-next-line import/no-cycle
import HTTPKit from '../HTTPKit';

// Constants

export const tokenRefreshEndpoint = '/api/v1/auth/api-token-refresh/';
export const authEndpoint = '/api/v1/auth/api-token-auth/';

export const COOKIES = {
  ACCESS_TOKEN: 'vetfamily.accessToken',
  REFRESH_TOKEN: 'vetfamily.refreshToken',
};

// Types

export interface TokenResponse {
  access?: string;
  refresh?: string;
}

export interface FailedQueueObject {
  resolve: (accessToken: string) => void;
  reject: (reason?: any) => void;
}

// Functions

export const queueFailedRequest =
  (queue: FailedQueueObject[]) =>
  (
    resolve: FailedQueueObject['resolve'],
    reject: FailedQueueObject['reject'],
  ) => {
    queue.push({ resolve, reject });
  };

export const resolveRequest =
  (accessToken: string) =>
  ({ resolve }) =>
    resolve(accessToken);

export const rejectRequest =
  error =>
  ({ reject }) =>
    reject(error);

export const retryRequest =
  (request: AxiosRequestConfig) => (accessToken: string) => {
    if (request.headers) {
      request.headers.Authorization = `Bearer ${accessToken}`;
    } else {
      throw new Error('No headers present');
    }
    return HTTPKit.client.request(request);
  };

export const getRefreshedTokens = async (
  refreshToken?: string,
): Promise<TokenResponse['access']> =>
  new Promise((resolve, reject) => {
    APIKit.auth
      .refreshTokens(refreshToken)
      .then(async ({ data }: { data: TokenResponse }) => {
        await setAuthTokens(data);
        resolve(data.access);
      })
      .catch(async err => {
        removeTokens();
        reject(err);
      });
  });
