import React, { useState, useContext, useRef } from 'react';

import classNames from 'classnames';
import LanguageSwitcher from 'components/LanguageSwitcher/LanguageSwitcher';
import Logo from 'components/Logo/Logo';
import UserContext from 'contexts/UserContext';
import NotificationList from 'project/Notifications/components/NotificationList';
import { useTranslation } from 'react-i18next';
import { useOnClickOutside } from 'services/hooks';
import RouteKit from 'services/RouteKit';
import { logout } from 'services/withAuth';

import MobileLinkList from './MobileLinkList';
import styles from './MobileMenu.module.scss';
import UserTile from './UserTile';
import type { MobileMenuType } from '../types';

const LogOutButton = () => {
  const { t } = useTranslation();
  return (
    <button type="button" className={styles.logoutBtn} onClick={logout}>
      <i className="fas fa-power-off mr-1" aria-hidden="true" />
      {t('navigation.logout')}
    </button>
  );
};

const MobileMenu = ({ linkList }: MobileMenuType) => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const menuRef = useRef(null);
  const showLanguageSwitch = !!user?.member?.country.useLanguageSelector;

  useOnClickOutside(menuRef, () => {
    setMenuIsOpen(false);
  });

  return (
    <div ref={menuRef}>
      <div className={`${styles.mainNavCompact} shadow-sm`}>
        <button
          type="button"
          className={styles.mobileMenuTrigger}
          onClick={() => setMenuIsOpen(!menuIsOpen)}
        >
          <i className="fas fa-bars" />
          <span>Menu</span>
        </button>
        <div className={styles.logoWrapper}>
          <Logo
            link={RouteKit.index}
            alt={t('navigation.links.home')}
            width={144}
          />
        </div>
        <NotificationList asLink />
      </div>
      <div
        className={classNames(styles.mobileMenuWrapper, 'shadow', {
          [styles.isOpen]: menuIsOpen,
        })}
      >
        <div style={{ overflow: 'scroll' }}>
          <div className={styles.mobileMenuHeader}>
            <div className={styles.logoWrapper}>
              <Logo
                onClick={() => {
                  if (menuIsOpen) {
                    setMenuIsOpen(false);
                  }
                }}
                link={RouteKit.index}
                alt={t('navigation.links.home')}
                width={144}
              />
            </div>
            <div>
              <button
                type="button"
                className="btn text-gray-500"
                onClick={() => setMenuIsOpen(!menuIsOpen)}
              >
                <i className="fas fa-times" style={{ width: '10px' }} />
              </button>
            </div>
          </div>
          <MobileLinkList
            closeMenu={() => {
              setMenuIsOpen(false);
            }}
            linkList={linkList}
          />
        </div>
        {menuIsOpen && (
          <div>
            <UserTile />
            <LogOutButton />
            {showLanguageSwitch && <LanguageSwitcher />}
          </div>
        )}
      </div>
    </div>
  );
};

export default MobileMenu;
