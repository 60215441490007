const Settings = {
  me: {
    as: '/settings/me',
    href: '/settings/me',
  },
  users: {
    as: '/settings/manage-users',
    href: '/settings/manage-users',
  },
  costumers: {
    as: '/settings/manage-costumers',
    href: '/settings/manage-costumers',
  },
};
export default Settings;
