/* eslint-disable no-return-await */
/* eslint-disable no-undef */
import i18next, { TFunction } from 'i18next';
import i18nextLocizeBackend from 'i18next-locize-backend';
import { locizePlugin } from 'locize';
import locizeLastUsed from 'locize-lastused';
import { initReactI18next } from 'react-i18next';

import { LOCALES } from './locale';
import { isServer } from './serverOrClient';

const IS_DEVELOPMENT = process.env.APP_STAGE === 'development';

enum LoadTypes {
  CurrentOnly = 'currentOnly',
  All = 'all',
  LanguageOnly = 'languageOnly',
}
/**
 * Common options shared between all locize/i18next plugins
 *
 * @see https://github.com/locize/i18next-locize-backend#backend-options
 * @see https://github.com/locize/locize-node-lastused#options
 */
export const locizeOptions = {
  projectId: process.env.LOCIZE_PROJECT_ID!,
  apiKey: IS_DEVELOPMENT || isServer() ? process.env.LOCIZE_API_KEY : undefined,
  referenceLng: 'en',
  version: 'latest', // XXX On production, use a dedicated production version
  // XXX Only define the API key on the server, for all environments (allows to use saveMissing)
};
const locizeLastUsedOptions = {
  ...locizeOptions,
  debounceSubmit: 30000,
  allowedHosts: ['localhost'],
};

/**
 * Specific options for the selected Locize backend.
 * @see https://github.com/locize/i18next-locize-backend#backend-options
 */

export const locizeBackendOptions = {
  ...locizeOptions,
  loadPath: 'https://api.locize.io/{{projectId}}/{{version}}/{{lng}}/{{ns}}',
  addPath:
    'https://api.locize.io/missing/{{projectId}}/{{version}}/{{lng}}/{{ns}}',
  allowedAddOrUpdateHosts: ['localhost', 'vetfamily-staging.now.sh'],
};

/**
 * Configure i18next with Locize backend.
 *
 * - Initialized with pre-defined "lang" (to make sure GraphCMS and Locize are configured with the same language)
 * - Initialized with pre-fetched "defaultLocales" (for SSR compatibility)
 * - Fetches translations from Locize backend
 * - Automates the creation of missing translations using "saveMissing: true"
 * - Display Locize "in-context" Editor when appending "/?locize=true" to the url (e.g http://localhost:8888/?locize=true)
 * - Automatically "touches" translations so it's easier to know when they've been used for the last time,
 *    helping translators figuring out which translations are not used anymore so they can delete them
 *
 * XXX We don't rely on https://github.com/i18next/i18next-browser-languageDetector because we have our own way of resolving the language to use, using utils/locale
 *
 * @param lang
 * @param defaultLocales
 */
const i18nextLocize = async (
  lang: any,
  defaultLocales: any,
): Promise<TFunction> => {
  // passes i18next down to react-i18next
  i18next.use(initReactI18next);
  i18next.use(locizeLastUsed);
  i18next.use(i18nextLocizeBackend);

  // Dynamically load different modules depending on whether we're running node or browser engine
  if (!isServer()) {
    i18next.use(locizePlugin);
    // const languageDetector = require('i18next-browser-languagedetector');
    const languageDetector = await import('i18next-browser-languagedetector');
    i18next.use(languageDetector.default);
  }

  const i18nOptions = {
    // XXX See https://www.i18next.com/overview/configuration-options
    backend: locizeBackendOptions,
    debug: false,
    defaultNS: 'common',
    editor: {
      ...locizeOptions,
      onEditorSaved: async (lng, ns): Promise<void> => {
        // reload that namespace in given language
        await i18next.reloadResources(lng, ns);
        // trigger an event on i18n which triggers a rerender
        // based on bindI18n below in react options
        i18next.emit('editorSaved');
      },
    },
    fallbackLng: () => {
      if (IS_DEVELOPMENT) {
        return ['dev'];
      }
      if (!lang) {
        return [LOCALES.EN];
      }
      const fallbacks = [lang];
      // If user has selected the test lang, we want to display the keys
      if (lang !== LOCALES.TEST) {
        // else fallback to English if lang is Swedish, and Swedish if lang i English
        fallbacks.push(lang === LOCALES.EN ? LOCALES.SE : LOCALES.EN);
      }

      // finally, add developer language (will be default for LOCALES.TEST ('XX'))
      fallbacks.push('dev');
      return fallbacks;
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    // XXX We don't use the built-in i18next-browser-languageDetector
    // because we have our own way of detecting language, which must
    // behave identically for both GraphCMS I18n and react-I18n
    lng: lang,
    // load: 'currentOnly', // Remove if you want to use localization (en-US, en-GB)
    load: LoadTypes.CurrentOnly,
    locizeLastUsed: locizeLastUsedOptions,
    ns: 'common',
    react: {
      bindI18n: 'languageChanged editorSaved',
      useSuspense: false, // Not compatible with SSR
    },
    resources: defaultLocales,
  };

  return await i18next.init(i18nOptions);
};

export default i18nextLocize;
