// eslint-disable-next-line import/no-cycle
import HTTPKit from '../HTTPKit';

const BEngagedEndpoints = {
  getList: (queryOptions?: { page?: number }) => {
    const url = '/api/v1/costumers/';
    return HTTPKit.get(url, queryOptions);
  },
  exportSpreadsheet: (formData: FormData) => {
    const url = '/api/v1/costumers/';
    return HTTPKit.post(url, formData);
  },
};

export default BEngagedEndpoints;
