export const languageFlags = {
  XX: '👨🏻‍💻',
  cs: '🇨🇿',
  da: '🇩🇰',
  de: '🇩🇪',
  en: '🇬🇧',
  es: '🇪🇸',
  fr: '🇫🇷',
  nl: '🇳🇱',
  'nl-BE': '🇧🇪',
  'fr-BE': '🇧🇪',
  nb: '🇳🇴',
  pl: '🇵🇱',
  'pt-BR': '🇧🇷',
  sk: '🇸🇰',
  sv: '🇸🇪',
  'en-AU': '🇦🇺',
};
export const countryFlags = {
  'Czech Republic': languageFlags.cs,
  Belgium: languageFlags['nl-BE'],
  Brazil: languageFlags['pt-BR'],
  Australia: languageFlags['en-AU'],
  Denmark: languageFlags.da,
  England: languageFlags.en,
  France: languageFlags.fr,
  Germany: languageFlags.de,
  Netherlands: languageFlags.nl,
  Norway: languageFlags.nb,
  Poland: languageFlags.pl,
  Showroom: '🏳️',
  Slovakia: languageFlags.sk,
  Spain: languageFlags.es,
  Sweden: languageFlags.sv,
  Test: languageFlags.XX,
};
