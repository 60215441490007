import campaigns from './routes/campaigns';
import commitments from './routes/commitments';
import contacts from './routes/contacts';
import digest from './routes/digest';
import directads from './routes/directads';
import events from './routes/events';
import forum from './routes/forum';
import handbooks from './routes/handbooks';
import login from './routes/login';
import news from './routes/news';
import notifications from './routes/notifications';
import partners from './routes/partners';
import reports from './routes/reports';
import services from './routes/services';
import settings from './routes/settings';
import vetData from './routes/vetData';
import wiki from './routes/wiki';

export type RouteObject = {
  /** The path that will be rendered in the browser URL bar. */
  as: string;
  /** The path inside pages directory. */
  href: string;
};

type DynamicRoute = (__0?: any) => RouteObject;

export type RouteSegment =
  | RouteObject
  | Record<
      string,
      RouteObject | DynamicRoute | Record<string, RouteObject | DynamicRoute>
    >;

const RouteKit = {
  campaigns,
  commitments,
  contacts,
  digest,
  directads,
  events,
  forum,
  handbooks,
  index: { href: '/', as: '/' },
  login,
  news,
  notifications,
  partners,
  release: { href: '/release', as: '/release' },
  reports,
  services,
  settings,
  vetData,
  wiki,
};

export default RouteKit;
