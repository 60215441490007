import React, { useEffect } from 'react';

import { datadogRum } from '@datadog/browser-rum';
import Cookie from 'js-cookie';
import { NextPageContext } from 'next';
import Router from 'next/router';
import nextCookie from 'next-cookies';

import APIKit from './APIKit';
import {
  COOKIES,
  getRefreshedTokens,
  TokenResponse,
} from './APIKit/interceptors/helpers';
import { logError } from './datadog';
import RouteKit from './RouteKit';
import { isClient } from './serverOrClient';

const in15Minutes = new Date(new Date().getTime() + 15 * 60 * 1000);
const ACCESS_TOKEN_EXPIRATION_TIME = in15Minutes;
const REFRESH_TOKEN_EXPIRATION_TIME = 30;

const handleRedirectToLogin = (ctx?: NextPageContext): void => {
  if (ctx?.res && ctx?.req) {
    ctx.res.writeHead(302, {
      Location: `${RouteKit.login.index().as}?next=${ctx.asPath}`,
    });
    ctx.res.end();
  } else {
    const loginRoute = ctx?.asPath
      ? RouteKit.login.index({ next: ctx.asPath })
      : RouteKit.login.index();
    Router.push(loginRoute.href, loginRoute.as);
  }
};

// eslint-disable-next-line no-underscore-dangle
const _setAccessTokenCookie = async (accessToken: string): Promise<void> => {
  Cookie.set(COOKIES.ACCESS_TOKEN, accessToken, {
    expires: ACCESS_TOKEN_EXPIRATION_TIME,
  });
};

// eslint-disable-next-line no-underscore-dangle
const _setRefreshTokenCookie = async (refreshToken: string): Promise<void> => {
  Cookie.set(COOKIES.REFRESH_TOKEN, refreshToken, {
    expires: REFRESH_TOKEN_EXPIRATION_TIME,
  });
};

export const setAuthTokens = async (tokens: TokenResponse): Promise<void> => {
  if (tokens.access) await _setAccessTokenCookie(tokens.access);
  if (tokens.refresh) await _setRefreshTokenCookie(tokens.refresh);
};

export const removeTokens = async (): Promise<void> => {
  Cookie.remove(COOKIES.ACCESS_TOKEN);
  Cookie.remove(COOKIES.REFRESH_TOKEN);
};

export const login = async (
  accessToken: string,
  nextQuery?: string | boolean,
): Promise<void> => {
  const a = await setAuthTokens({ access: accessToken });
  if (nextQuery) {
    if (isClient()) {
      Router.push(nextQuery.toString());
    }
  } else {
    const homeRoute = RouteKit.index;
    if (isClient()) {
      Router.push(homeRoute.href, homeRoute.as);
    }
  }
};

export const login2 = async (
  access: string,
  refresh: string,
  nextQuery?: string | boolean,
): Promise<void> => {
  await setAuthTokens({ access, refresh });
  if (nextQuery) {
    if (isClient()) {
      Router.push(nextQuery.toString());
    }
  } else {
    const homeRoute = RouteKit.index;
    if (isClient()) {
      try {
        APIKit.stream.postActivityStream('/login');
      } catch (error) {
        logError({ error });
      }
      Router.push(homeRoute.href, homeRoute.as);
    }
  }
};

export const logout = async (): Promise<void> => {
  // Let's not wait for activity stream to finish before logging out user
  APIKit.stream.postActivityStream('/logout').catch(error => {
    logError({
      error,
      context: {
        message: 'Error posting logout activity stream',
      },
    });
  });

  removeTokens();
  datadogRum.clearUser();
  // to support logging out from all windows
  window.localStorage.setItem('logout', Date.now().toString());
  window.sessionStorage.removeItem('reports_member_id');
  const loginRoute = RouteKit.login.index();

  Router.push(loginRoute.href, loginRoute.as); // TODO October 21, 2020: Check for server
};

export const getRefreshTokenFromCookie = (): string | undefined =>
  Cookie.get(COOKIES.REFRESH_TOKEN);

export const auth = async (ctx: NextPageContext): Promise<string[]> => {
  let accessToken = ctx.req
    ? nextCookie(ctx)[COOKIES.ACCESS_TOKEN]
    : Cookie.get(COOKIES.ACCESS_TOKEN);
  const refreshToken: any = ctx.req
    ? nextCookie(ctx)[COOKIES.REFRESH_TOKEN]
    : Cookie.get(COOKIES.REFRESH_TOKEN);

  try {
    // if we don't have a valid access token, fetch new from refresh token
    if (!accessToken) {
      accessToken = await getRefreshedTokens(refreshToken);
    }
    // this will throw an error if token is invalid, and return 200 if it is valid
    const verifyAccessTokenRequest = await APIKit.auth.verifyToken(
      accessToken || '',
    );
  } catch (error) {
    // so bad token, or no token we end up here, redirect to login
    handleRedirectToLogin(ctx);
  }

  return [accessToken, refreshToken];
};

const WithAuth = (WrappedComponent: any): any => {
  const Wrapper = (props: any): JSX.Element => {
    const syncLogout = (event: any): void => {
      if (event.key === 'logout') {
        const loginRoute = RouteKit.login.index();
        if (isClient()) {
          Router.push(loginRoute.href, loginRoute.as);
        }
      }
    };

    useEffect((): any => {
      window.addEventListener('storage', syncLogout);

      return (): void => {
        window.removeEventListener('storage', syncLogout);
        window.localStorage.removeItem('logout');
      };
    }, [null]);

    // eslint-disable-next-line react/jsx-props-no-spreading
    return <WrappedComponent {...props} />;
  };

  Wrapper.getInitialProps = async (
    ctx: NextPageContext,
  ): Promise<Record<string, any>> => {
    const [token, refreshToken] = await auth(ctx);

    let componentProps: any = {};
    if (WrappedComponent.getInitialProps) {
      componentProps = await WrappedComponent.getInitialProps(ctx);
    }

    return { ...componentProps, token, refreshToken };
  };

  Wrapper.hideMenu = WrappedComponent.hideMenu;

  return Wrapper;
};

export default WithAuth;
