const Digest = {
  list: { as: '/digest', href: '/digest' },
  subscribers: {
    list: { as: '/digest/subscribers', href: '/digest/subscribers' },
  },
  headerGifs: {
    list: { as: '/digest/headergifs', href: '/digest/headergifs' },
  },
};
export default Digest;
