import sluggify from 'services/sluggify';

const News = {
  list: { as: '/news', href: '/news' },
  detail: (id: number, title?: string) => ({
    as: `/news/${id}${title ? `-${sluggify(title)}` : ''}`,
    href: '/news/[id]',
  }),
  create: { as: '/news/admin', href: '/news/admin' },
  update: (id: number) => ({
    as: `/news/admin/${id}`,
    href: '/news/admin/[id]',
  }),
};
export default News;
