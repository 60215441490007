// eslint-disable-next-line import/no-cycle
import HTTPKit from '../HTTPKit';

const TagsEndpoints = {
  getList: () => {
    const url = '/api/v1/tags/';
    return HTTPKit.get(url);
  },
};
export default TagsEndpoints;
