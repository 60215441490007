import { CancelTokenCallback } from 'services/useSearchHook';

// eslint-disable-next-line import/no-cycle
import HTTPKit from '../HTTPKit';

interface PartnerListRequest {
  page?: number;
  name?: string;
  specializations?: number[] | null;
}

const PartnerEndpoints = {
  getFilters: () => {
    const url = '/api/v1/partners/specializations/';
    return HTTPKit.get(url);
  },
  getList: (
    queryOptions?: {
      page_size?: number;
      page?: number;
      name?: string;
      specializations?: string;
    },
    cancelTokenCallback?: CancelTokenCallback,
  ) => {
    const url = '/api/v1/partners/';
    return HTTPKit.get(
      url,
      queryOptions,
      cancelTokenCallback &&
        HTTPKit.axiosCancelTokenAsigner(cancelTokenCallback),
    );
  },
  getDetail: (id: number) => {
    const url = `/api/v1/partners/${id}/`;
    return HTTPKit.get(url);
  },
  discoveryTiles: {
    getList: () => {
      const url = '/api/v1/partners/discovery/';
      return HTTPKit.get(url);
    },
    getDetail: (id: number, queryOptions?: { name?: string }) => {
      const url = `/api/v1/partners/discovery/${id}/`;
      return HTTPKit.get(url, queryOptions);
    },
    detail: {
      getPartners: (
        queryOptions: {
          discoveryTileId?: number;
          name?: string;
          categories?: string;
          status?: string;
        },
        cancelTokenCallback?: CancelTokenCallback,
      ) => {
        const name = queryOptions?.name ?? '';
        const specializations = queryOptions?.categories ?? '';
        const status = queryOptions?.status ?? undefined;
        const url = `/api/v1/partners/discovery/${queryOptions.discoveryTileId}/partners/`;
        return HTTPKit.get(
          url,
          {
            name,
            specializations,
            status,
          },
          cancelTokenCallback &&
            HTTPKit.axiosCancelTokenAsigner(cancelTokenCallback),
        );
      },
      getFilters(id: number) {
        const url = `/api/v1/partners/discovery/${id}/partners/specializations/`;
        return HTTPKit.get(url);
      },
    },
  },
  getAllPartnerContractsFile: () => {
    const url = '/api/v1/all-partner-contracts-file/';
    return HTTPKit.get(url);
  },
  getPartnerContactRequestSubjects: () => {
    const url = '/api/v1/all-partner-contact-request-subjects/';
    return HTTPKit.get(url);
  },
  createPartnerContactRequest: (id: number, formData: FormData) => {
    const url = `/api/v1/partners/${id}/request-contact/`;
    return HTTPKit.post(url, formData);
  },
  createPartner: (formData: FormData) => {
    const url = '/api/v1/partners/';
    return HTTPKit.post(url, formData);
  },
  updatePartner: (id: number, formData: FormData) => {
    const url = `/api/v1/partners/${id}/`;
    return HTTPKit.patch(url, formData);
  },
  deletePartner: (id: number) => {
    const url = `/api/v1/partners/${id}/`;
    return HTTPKit.delete(url, {});
  },
  destroyFile: (id: number, kind: number) => {
    let url;
    if (kind === 0) url = `/api/v1/partner-files/${id}/`;
    else url = `/api/v1/partner-contract-file/${id}/`;
    return HTTPKit.delete(url, {});
  },
  updateFile: (id: number, kind: number, formData: FormData) => {
    let url;
    if (kind === 0) url = `/api/v1/partner-files/${id}/`;
    else url = `/api/v1/partner-contract-file/${id}/`;
    return HTTPKit.patch(url, formData);
  },
  saleRep: {
    getList: (partner?: number) => {
      const url = '/api/v1/partner-sale-rep/';
      return HTTPKit.get(url, { partner });
    },
    deleteSalesRep: (id: number) => {
      const url = `/api/v1/partner-sale-rep/${id}/`;
      return HTTPKit.delete(url, {});
    },
    updateSalesRep: (id: number, formData: FormData) => {
      const url = `/api/v1/partner-sale-rep/${id}/`;
      return HTTPKit.patch(url, formData);
    },
  },
  destroyGlobalContract: (id: number) => {
    const url = `/api/v1/all-partner-contracts-file/${id}/`;
    return HTTPKit.delete(url, {});
  },
  createGlobalContract: (formData: FormData) => {
    const url = '/api/v1/all-partner-contracts-file/';
    return HTTPKit.post(url, formData);
  },
  destroyLogo: (id: number) => {
    const url = `/api/v1/partner-logo/${id}/`;
    return HTTPKit.delete(url, {});
  },
  updateLogo: (id: number, formData: FormData) => {
    const url = `/api/v1/partner-logo/${id}/`;
    return HTTPKit.patch(url, formData);
  },
  createLogo: (formData: FormData) => {
    const url = '/api/v1/partner-logo/';
    return HTTPKit.post(url, formData);
  },
};
export default PartnerEndpoints;
