const Reports = {
  index: { as: '/reports', href: '/reports' },
  SpendAndSavings: { as: '/reports/dashboard', href: '/reports/dashboard' },
  QuarterlyReports: { as: '/reports/quarterly', href: '/reports/quarterly' },
  QuarterlyReportByPeriod: (year: string, quarter: string) => ({
    as: `/reports/quarterly/year/${year}/quarter/${quarter}`,
    href: '/reports/quarterly/year/[year]/quarter/[quarter]',
  }),
};

export default Reports;
