export interface EdgeDataInterface {
  backendDeployInProgress?: boolean;
  deployDate?: string;
  estimatedDownTimeInMinutes?: number;
  frontendDepolyInProgress?: boolean;
  infoMessage?: {
    showMessage: boolean;
    eventFromTime?: string;
    eventToTime?: string;
  };
}
export const getEdgeData = async (): Promise<EdgeDataInterface> => {
  const { items } = await fetch(process.env.EDGE_CONFIG!).then(res =>
    res.json(),
  );
  return items;
};
