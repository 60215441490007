import React from 'react';

import { useTranslation } from 'react-i18next';

import styles from '../FormInputs/FormInputs.module.scss';

interface SubmitButtonProps {
  disabled?: boolean;
  isSubmitting: boolean;
  text: string;
  className?: string;
  onSubmit?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const SubmitButton = ({
  isSubmitting,
  text,
  className,
  disabled,
  onSubmit,
}: SubmitButtonProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <button
      type={onSubmit ? 'button' : 'submit'}
      className={className || `btn-block ${styles.formikButton}`}
      disabled={isSubmitting || disabled}
      data-test="submitButton"
      onClick={e => {
        if (onSubmit) {
          onSubmit(e);
        }
      }}
    >
      {!isSubmitting && text}{' '}
      {!isSubmitting && (
        <i className="fas fa-chevron-right ml-1" aria-hidden="true" />
      )}
      {isSubmitting && (
        <div
          className="spinner-border spinner-border-sm text-light"
          role="status"
          data-test="spinner"
        >
          <span className="sr-only">{t('general.loading')}</span>
        </div>
      )}
    </button>
  );
};

export default SubmitButton;
