import HTTPKit from '../HTTPKit';

const key = '/api/v1/clickstream/activity/';

type ClickProps = {
  actionDetails: string;
  context?: string;
  data: any;
};

type MutationProps = {
  arg: ClickProps;
};

export const mutations = {
  trackClick: {
    key,
    mutate: (url: string, props: MutationProps) =>
      HTTPKit.post(url, {
        actionType: 'button_clicked',
        ...props.arg,
      }),
  },
};
const SWRLocale = { mutations };

export default SWRLocale;
