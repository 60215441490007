// eslint-disable-next-line import/no-cycle
import HTTPKit from '../HTTPKit';

const DirectAdsEndpoints = {
  getFilters: () => {
    const url = `/api/v1/direact-ads/categories/`;
    return HTTPKit.get(url);
  },
  getList: (queryOptions?: {
    page?: number;
    category?: number | string;
    filter?: number | string;
    title?: string;
    author?: number;
  }) => {
    const url = '/api/v1/direact-ads/';
    return HTTPKit.get(url, queryOptions);
  },
  getDetail: (id: string) => {
    const url = `/api/v1/direact-ads/${id}/`;
    return HTTPKit.get(url);
  },
  createDirectAd: (formData: FormData) => {
    const url = `/api/v1/direact-ads/`;
    return HTTPKit.post(url, formData);
  },
  updateDirectAd: (id: number, formData: FormData) => {
    const url = `/api/v1/direact-ads/${id}/`;
    return HTTPKit.patch(url, formData);
  },
  deleteDirectAd: (id: number) => {
    const url = `/api/v1/direact-ads/${id}/`;
    return HTTPKit.delete(url, {});
  },
  emailDirectAdCreator: (id: number, message: string) => {
    const url = `/api/v1/direact-ads/${id}/email/`;
    return HTTPKit.post(url, { message });
  },
};

export default DirectAdsEndpoints;
