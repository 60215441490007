import qs from 'query-string';

const Login = {
  index: (query?: { next: string }) => {
    if (query && query.next) {
      return {
        as: `/login?next=${query.next}`,
        href: `/login?next=${query.next}`,
      };
    }
    return { as: '/login', href: '/login' };
  },
  resetPassword: {
    as: '/login/reset-password',
    href: '/login/reset-password',
  },
  setPassword: (query: {
    uid: string;
    token: string;
    email: string;
    refreshToken: string;
    step: string;
  }) => {
    const queryString = qs.stringify(query);
    return {
      as: `/login/set-password?${queryString}`,
      href: `/login/set-password?${queryString}`,
    };
  },
};
export default Login;
