import sluggify from 'services/sluggify';

const Services = {
  list: { as: '/services', href: '/services' },
  detail: (id: number, title?: string) => ({
    as: `/services/${id}${title ? `-${sluggify(title)}` : ''}`,
    href: '/services/[id]',
  }),
  create: { as: '/services/admin', href: '/services/admin' },
  update: (id: number) => ({
    as: `/services/admin/${id}`,
    href: '/services/admin/[id]',
  }),
};
export default Services;
