import React from 'react';

const LoadingSpinner = () => (
  <div className="spinner-wrapper">
    <div className="spinner" />
    <img src="/img/vetfamily-symbol-red@3x.png" alt="" width="18" />
  </div>
);

export default LoadingSpinner;
