// eslint-disable-next-line import/no-cycle
import HTTPKit from '../HTTPKit';

type UpdateLocaleProps = {
  language?: number;
  member?: { country?: number; id?: number };
  role?: number;
};

const DevControlEndpoints = {
  getLocaleOptions: () => {
    const url = '/api/v1/dev/locale-options/';
    return HTTPKit.get(url);
  },
  updateLocale: (payload: UpdateLocaleProps) => {
    const url = '/api/v1/dev/country-switcher/';
    return HTTPKit.patch(url, payload);
  },
};

export default DevControlEndpoints;
