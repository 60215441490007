import React, { useContext, useEffect, useState } from 'react';

import classNames from 'classnames';
import UserContext from 'contexts/UserContext';
import { FeatureFlagContext } from 'pages/_app';
import { queries as SWRHandbookQueries } from 'services/APIKit/SWR/handbooks';
import { HanbooksList } from 'services/APIKit/types';
import useSWR from 'swr';

import DesktopMenu from './components/DesktopMenu';
import MobileMenu from './components/MobileMenu';
import styles from './NavMenu.module.scss';
import type { MenuLink, NavMenuProps } from './types';
import { getNavMenuLinks } from './utils';

const {
  getList: { key: HANDBOOK_KEY, fetcher: fetchHandbooks },
} = SWRHandbookQueries;

const NavMenu = ({ displayMobile }: NavMenuProps): JSX.Element => {
  const { data } = useSWR<HanbooksList>(HANDBOOK_KEY, fetchHandbooks);

  const { data: handbooks } = data || {};

  const { user } = useContext(UserContext);
  const featureFlags = useContext(FeatureFlagContext);

  const [links, setLinks] = useState<MenuLink[]>();
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  useEffect(() => {
    const linkList = getNavMenuLinks(
      featureFlags,
      user,
      !!handbooks?.count,
      displayMobile,
    );
    setShowMobileMenu(displayMobile);
    setLinks(linkList);
  }, [featureFlags, user, handbooks, displayMobile]);

  return (
    <div className={classNames(styles.navMenuWrapper, 'd-print-none')}>
      {showMobileMenu ? (
        <MobileMenu linkList={links} />
      ) : (
        <DesktopMenu linkList={links} />
      )}
    </div>
  );
};

export default NavMenu;
