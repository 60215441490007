import React from 'react';

import LanguageSwitcher from 'components/LanguageSwitcher/LanguageSwitcher';
import { queries as SWRLocaleQueries } from 'services/APIKit/SWR/locale';
import useSWR from 'swr';

import styles from '../DevControl.module.scss';

const {
  getLocaleOptions: { key: GET_LOCALE, fetcher: getLocaleOptions },
} = SWRLocaleQueries;

const LanguageList = () => {
  const { data: options } = useSWR(GET_LOCALE, getLocaleOptions);

  return (
    <>
      <h3 className={styles.h3}>
        <i className="text-gray-600 fas fa-language mr-1" /> Select Language:
      </h3>
      <LanguageSwitcher
        context="dev controls"
        selectedClass={styles.selected}
        componentClass={styles.itemList}
        itemClass={styles.listItemButton}
        iconClass={styles.flagIcon}
        languages={options.languages.sort((a, b) => {
          if (a.name > b.name) return -1;
          if (a.name < b.name) return 1;
          return 0;
        })}
        displayFlags
      />
    </>
  );
};

export default LanguageList;
