import React, { Suspense, lazy, useContext, useRef, useState } from 'react';

import classNames from 'classnames';
import CenteredSpinner from 'components/Loading/CenteredSpinner';
import UserContext from 'contexts/UserContext';
import { useOnClickOutside } from 'services/hooks';

import CountryList from './components/CountryList';
import DisplayOptions from './components/DisplayOptions';
import FeatureFlags from './components/FeatureFlags';
import LanguageList from './components/LanguageList';
import { DisplayOptionsType } from './components/MemberMeta';
import TinyOptions from './components/TinyOptions';
import UserRoleSelector from './components/UserRoleSelector';
import ViewAsMember from './components/ViewAsMember';
import styles from './DevControl.module.scss';

const MemberSelector = lazy(() => import('./components/MemberSelector'));
const MemberMeta = lazy(() => import('./components/MemberMeta'));

const DevControls = () => {
  const { user } = useContext(UserContext);

  const popup = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const { obfuscate } = useContext(UserContext);

  const localStorageKey = `${user.id}.devControls.displayoptions`;
  const initValue = window.localStorage.getItem(`${localStorageKey}`) || '{}';
  const defaultDisplayOptions = {
    displayCountry: true,
    displayMemberName: true,
    displayMemberLevels: true,
    displayUserRoles: true,
  };

  const [displayOptions, setDisplayOptions] = useState<DisplayOptionsType>({
    ...defaultDisplayOptions,
    ...JSON.parse(initValue),
  });

  useOnClickOutside(popup, () => {
    setIsOpen(false);
  });

  const toggleDevControls = () => {
    setIsOpen(curent => !curent);
  };

  return (
    <div
      ref={popup}
      className={classNames('d-print-none', 'shadow', styles.devControl)}
    >
      {isOpen && (
        <div className="p-3">
          <div className="mb-2">
            <Suspense
              fallback={
                <div className={styles.userMemberFallback}>
                  <CenteredSpinner />
                </div>
              }
            >
              <MemberSelector />
              <UserRoleSelector />
            </Suspense>
          </div>
          <hr />
          <TinyOptions />
          <hr />
          <ViewAsMember />
          {user.isStaff && (
            <>
              <FeatureFlags />
              <CountryList />
              <LanguageList />
            </>
          )}
          <hr />
          <DisplayOptions
            localStorageKey={localStorageKey}
            setDisplayOptions={setDisplayOptions}
            displayOptions={displayOptions}
          />
        </div>
      )}
      <Suspense>
        <MemberMeta
          isOpen={isOpen}
          displayOptions={displayOptions}
          obfuscate={obfuscate}
          toggleDevControls={toggleDevControls}
        />
      </Suspense>
    </div>
  );
};

export default DevControls;
