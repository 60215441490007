import HTTPKit from '../HTTPKit';

export const queries = {
  getList: {
    key: '/api/v1/wiki/handbooks/',
    fetcher: async () => {
      const url = '/api/v1/wiki/handbooks/';
      return HTTPKit.get(url);
    },
  },
};

const SWRNotifications = { queries };

export default SWRNotifications;
