import React, { useContext } from 'react';

import classNames from 'classnames';
import UserContext from 'contexts/UserContext';
import { useRouter } from 'next/router';
import APIKit from 'services/APIKit';
import { queries as SWRLocaleQueries } from 'services/APIKit/SWR/locale';
import { countryFlags } from 'services/flags';
import useSWR from 'swr';

import styles from '../DevControl.module.scss';

const {
  getLocaleOptions: { key: GET_LOCALE, fetcher: getLocaleOptions },
} = SWRLocaleQueries;

const CountryList = () => {
  const { data: options } = useSWR(GET_LOCALE, getLocaleOptions);
  const { user } = useContext(UserContext);
  const router = useRouter();

  const changeCountry = async (country: number) => {
    await APIKit.dev.updateLocale({ member: { country } });
    router.reload();
  };

  return (
    <>
      <h3 className={styles.h3}>
        <i className="text-gray-600 fas fa-globe-americas mr-1" /> Select
        Country:
      </h3>

      <div className={styles.itemList}>
        {options.countries
          .sort((a, b) => {
            if (a.name > b.name) return -1;
            if (a.name < b.name) return 1;
            return 0;
          })
          .map(country => (
            <button
              key={`country-${country.id}`}
              onClick={() => {
                changeCountry(country.id);
              }}
              type="button"
              className={classNames(styles.listItemButton, {
                [styles.selected]: user.member?.country.id === country.id,
              })}
              disabled={!user.member?.isVetfamily}
            >
              <span className={styles.flagIcon}>
                {countryFlags[country.name]}
              </span>
              {country.name}
            </button>
          ))}
      </div>
    </>
  );
};

export default CountryList;
