import React from 'react';

import Logo from 'components/Logo/Logo';
import NextStep from 'project/Login/components/UserOnboarding/NextStep/NextStep';
import styles from 'project/Login/components/UserOnboarding/UserOnboarding.module.scss';
import { useTranslation } from 'react-i18next';
import { Feature } from 'services/constants';

interface Step2Props {
  handleRouting(step: string): void;
  featureFlags: number[];
}

const Step2 = ({ handleRouting, featureFlags }: Step2Props) => {
  const { t } = useTranslation();
  const handleNextStep = () => {
    const nextStep = '3';
    handleRouting(nextStep);
  };

  const showFeature = (feature: number) =>
    featureFlags.some(flag => flag === feature);

  return (
    <div
      className={`${styles.userOnboardingStep} ${styles.userOnboardingStep2}`}
    >
      <Logo className={styles.logo} />
      <img src="/img/dogs.jpeg" alt="" className={styles.image} />
      {showFeature(Feature.SERVICES) && (
        <>
          <h1
            className={`font-weight-bold ${styles.headingLarge}`}
            style={{ fontSize: '18px' }}
          >
            {t('navigation.dropdown.vetFamily')}
          </h1>
          <p className="text-gray-600">
            <i className="text-green-500 fas fa-concierge-bell fa-sm mr-2" />
            <strong className="text-green-500 mr-2">
              {t('navigation.links.services')}
            </strong>
            {t('onboarding.vetFamily.description')}
          </p>
          <hr />
        </>
      )}
      {(showFeature(Feature.PARTNERS) || showFeature(Feature.CAMPAIGNS)) && (
        <>
          <h1
            className={`font-weight-bold ${styles.headingLarge}`}
            style={{ fontSize: '18px' }}
          >
            {t('navigation.dropdown.partners')}
          </h1>
          {showFeature(Feature.PARTNERS) && (
            <p className="text-gray-600">
              <i className="text-green-500 fas fa-briefcase fa-sm mr-2" />
              <strong className="text-green-500 mr-2">
                {t('navigation.links.partners')}
              </strong>
              {t('onboarding.partners.offers.description')}
            </p>
          )}
          {showFeature(Feature.CAMPAIGNS) && (
            <p className="text-gray-600">
              <i className="text-green-500 fas fa-tag fa-sm mr-2" />
              <strong className="text-green-500 mr-2">
                {t('navigation.links.campaigns')}
              </strong>
              {t('onboarding.partners.campaigns.description')}
            </p>
          )}
          <hr />
        </>
      )}
      {(showFeature(Feature.EVENTS) ||
        showFeature(Feature.FORUM) ||
        showFeature(Feature.DIRECT_ADS)) && (
        <>
          <h1
            className={`font-weight-bold ${styles.headingLarge}`}
            style={{ fontSize: '18px' }}
          >
            {t('navigation.dropdown.community')}
          </h1>
          {showFeature(Feature.EVENTS) && (
            <p className="text-gray-600">
              <i className="text-green-500 fas fa-calendar fa-sm mr-2" />{' '}
              <strong className="text-green-500 mr-2">
                {t('navigation.links.events')}
              </strong>
              {t('onboarding.community.events.description')}
            </p>
          )}
          {showFeature(Feature.FORUM) && (
            <p className="text-gray-600">
              <i className="text-green-500 fas fa-comments fa-sm mr-2" />{' '}
              <strong className="text-green-500 mr-2">
                {t('navigation.links.forum')}
              </strong>
              {t('onboarding.community.forum.description')}
            </p>
          )}
          {showFeature(Feature.DIRECT_ADS) && (
            <p className="text-gray-600">
              <i className="text-green-500 fas fa-bullhorn fa-sm mr-2" />
              <strong className="text-green-500 mr-2">
                {t('navigation.links.directads')}
              </strong>
              {t('onboarding.community.directads.description')}
            </p>
          )}
          <hr />
        </>
      )}
      {(showFeature(Feature.REPORTS) || showFeature(Feature.SPEND_SAVINGS)) && (
        <>
          <img src="/img/svg/growth.svg" alt="" />
          <p className="text-gray-600 mt-2">
            <strong className="text-green-500 mr-2">
              {t('navigation.links.reports')}
            </strong>
            {t('onboarding.reports.description')}
          </p>
        </>
      )}
      <NextStep
        buttonLabel={t('general.next')}
        isSubmitting={false}
        onSubmit={handleNextStep}
      />
    </div>
  );
};

export default Step2;
