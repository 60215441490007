// eslint-disable-next-line import/no-cycle
import HTTPKit from '../HTTPKit';

const baseUrl: string = '/api/v1/clickstream/stream/';

export default {
  postActivityStream: (url: String, referral?: String) => {
    const payload = {
      url,
      kind: 0,
      referral,
    };
    return HTTPKit.post(baseUrl, payload);
  },
  postReportStream: (url: String, reportId: number) => {
    const payload = {
      url,
      kind: 1,
      report: reportId,
    };
    return HTTPKit.post(baseUrl, payload);
  },
  postNewsStream: (url: String, newsId: number) => {
    const payload = {
      url,
      kind: 2,
      news: newsId,
    };
    return HTTPKit.post(baseUrl, payload);
  },
  postEventStream: (url: String, eventId: number) => {
    const payload = {
      url,
      kind: 3,
      event: eventId,
    };
    return HTTPKit.post(baseUrl, payload);
  },
  postWikiStream: (url: String, wikiId: number) => {
    const payload = {
      url,
      kind: 4,
      wiki: wikiId,
    };
    return HTTPKit.post(baseUrl, payload);
  },
  postDirectAdStream: (url: String, directAdId: number) => {
    const payload = {
      url,
      kind: 5,
      directAd: directAdId,
    };
    return HTTPKit.post(baseUrl, payload);
  },
};
