// eslint-disable-next-line import/no-cycle
import HTTPKit from '../HTTPKit';

const FeatureFlagEndpoints = {
  getList: () => {
    const url = '/api/v1/feature-flags/';
    return HTTPKit.get(url);
  },
};
export default FeatureFlagEndpoints;
