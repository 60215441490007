import { getBossList } from 'services/helpers';

import HTTPKit from '../HTTPKit';

type GetListProps = {
  page?: number;
  role?: boolean;
  staff?: boolean;
  member?: boolean;
};
export const queries = {
  getCurrentUser: {
    key: '/api/v1/me/',
    fetcher: async url => HTTPKit.get(url).then(({ data }) => data),
  },
  getList: {
    fetcher: async (url: string, props: GetListProps) =>
      HTTPKit.get(url, props).then(({ data }) =>
        getBossList(data.results || []),
      ),
    key: '/api/v1/users/',
  },
};

type PatchUserProps = {
  arg: {
    payload: Record<string, any>;
    queryParams?: Record<string, any>;
  };
};
export const mutations = {
  patchUser: {
    key: '/api/v1/me/',
    mutate: async (url: string, { arg }: PatchUserProps) =>
      HTTPKit.patch(url, arg.payload, arg.queryParams),
  },
};

const SWRUser = { queries };
export default SWRUser;
