import { feature, rolesEnum } from 'services/helpers';

import type { HasAccessProps } from '../types';

const hasAccess = ({
  notification,
  featureFlags,
  user,
}: HasAccessProps): boolean => {
  const availableFeatures = featureFlags
    ?.filter(
      flag =>
        flag.available &&
        user.levels?.some(level => flag.levels.includes(level.id)),
    )
    .map(flag => feature[flag.feature]);
  if (!notification.actor) {
    return false;
  }

  if (
    !availableFeatures.includes(notification.description) &&
    notification.description !== 'Handbook'
  ) {
    return false;
  }

  if (notification.actor.levels.length === 0) {
    return true;
  }

  let userLevelMatch: boolean = true;
  if (rolesEnum.some(({ id }) => notification.actor.levels.includes(id))) {
    userLevelMatch = !!user.levels?.some(l =>
      notification.actor.levels.includes(l.id),
    );
  }

  return (
    userLevelMatch &&
    !!user.member?.levels?.some(l => notification.actor.levels.includes(l.id))
  );
};

export default hasAccess;
