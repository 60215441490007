import React from 'react';

import classNames from 'classnames';
import Logo from 'components/Logo/Logo';
import { useTranslation } from 'react-i18next';
import RouteKit from 'services/RouteKit';

import styles from './DesktopMenu.module.scss';
import MenuLinks from './MenuLinks';
import UserMenu from './UserMenu';
import type { MenuLinksProps } from '../types';

const DesktopMenu = ({ linkList }: MenuLinksProps) => {
  const { t } = useTranslation();
  return (
    <header className="container-fluid bg-white shadow-sm">
      <div className={classNames('container', styles.desktopMenuWrapper)}>
        <Logo
          link={RouteKit.index}
          alt={t('navigation.links.home')}
          className={styles.desktopMenuLogo}
          width={144}
        />

        <nav className={styles.desktopMenuNavbar}>
          <MenuLinks linkList={linkList} />
          <UserMenu />
        </nav>
      </div>
    </header>
  );
};

export default DesktopMenu;
