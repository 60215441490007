import React from 'react';

import { useTranslation } from 'react-i18next';

import { DisplayOptionsType } from './MemberMeta';
import ToggleButton from './ToggleButton';
import styles from '../DevControl.module.scss';

type DisplayOptionProps = {
  displayOptions: DisplayOptionsType;
  setDisplayOptions: Function;
  localStorageKey: string;
};

const DisplayOptions = ({
  displayOptions,
  setDisplayOptions,
  localStorageKey,
}: DisplayOptionProps) => {
  const { t } = useTranslation();
  return (
    <div className="mb-2">
      <h2 className={styles.h2}>Display Options:</h2>
      <ul className={styles.tinyOptionsList}>
        {Object.keys(displayOptions).map(key => (
          <ToggleButton
            key={key}
            id={key}
            label={t(`displayOptions.${key}`)}
            isChecked={displayOptions[key]}
            handleChange={() => {
              setDisplayOptions(prev => {
                const newData = { ...prev, [key]: !prev[key] };
                window.localStorage.setItem(
                  localStorageKey,
                  JSON.stringify(newData),
                );
                return newData;
              });
            }}
          />
        ))}
      </ul>
    </div>
  );
};

export default DisplayOptions;
