import React from 'react';

import Logo from 'components/Logo/Logo';
import Link from 'next/link';
import NextStep from 'project/Login/components/UserOnboarding/NextStep/NextStep';
import styles from 'project/Login/components/UserOnboarding/UserOnboarding.module.scss';
import { useTranslation } from 'react-i18next';
import { Feature } from 'services/constants';
import RouteKit from 'services/RouteKit';

interface Step3Props {
  featureFlags: number[];
  closeModal?(): void;
  isMutating?: boolean;
}

const Step3 = ({
  isMutating,
  featureFlags,
  closeModal,
}: Step3Props): JSX.Element => {
  const { t } = useTranslation();

  const showFeature = (feature: number) =>
    featureFlags.some(flag => flag === feature);

  return (
    <div className={styles.userOnboardingStep}>
      <Logo className={styles.logo} />
      <img
        src="/img/svg/connect.svg"
        alt=""
        className={`${styles.illustration}`}
      />
      {(showFeature(Feature.NEWS) || showFeature(Feature.WIKI)) && (
        <>
          <h1
            className={`font-weight-bold ${styles.headingLarge}`}
            style={{ fontSize: '18px' }}
          >
            {t('navigation.dropdown.knowledge')}
          </h1>
          {showFeature(Feature.NEWS) && (
            <p className="text-gray-600">
              <i className="text-green-500 fas fa-newspaper fa-sm mr-2" />
              <strong className="text-green-500 mr-2">
                {t('navigation.links.news')}
              </strong>
              {t('onboarding.knowledge.news.description')}
            </p>
          )}
          {showFeature(Feature.WIKI) && (
            <p className="text-gray-600">
              <i className="text-green-500 fas fa-graduation-cap fa-sm mr-2" />
              <strong className="text-green-500 mr-2">
                {t('navigation.links.wiki')}
              </strong>
              {t('onboarding.knowledge.wiki.description')}
            </p>
          )}
          <hr />
        </>
      )}
      <p className="text-gray-600">
        <i className="text-green-500 fas fa-user fa-sm mr-2" />
        <strong className="text-green-500 mr-2">
          {t('navigation.links.settings.me')}
        </strong>
        {t('onboarding.profile.description')}
      </p>
      <hr />
      <p className="text-gray-600">
        <i className="text-green-500 fas fa-bell fa-sm mr-2" />
        <strong className="text-green-500 mr-2">
          {t('pageTitle.notifications')}
        </strong>
        {t('onboarding.notification.description')}
      </p>
      <hr />
      <p className="text-gray-600">
        <strong className="text-green-500 mr-2">{t('digest.title')}</strong>
        {t('onboarding.digest.description')}
      </p>
      <hr />
      <h1
        className={`font-weight-bold ${styles.headingLarge}`}
        style={{ fontSize: '18px' }}
      >
        {t('onboarding.helpAndSuggestions')}
      </h1>
      <p className="text-gray-600">
        {`${t('onboarding.feelFreeTo')} `}
        <Link {...RouteKit.contacts}>{t('onboarding.contactUs')}</Link>
      </p>
      <hr />
      <p className="text-gray-600">{t('onboarding.thankYou')}</p>
      <NextStep
        buttonLabel={t('onboarding.lastStep.buttonLabel')}
        isSubmitting={!!isMutating}
        disabled={isMutating}
        onSubmit={event => {
          event.stopPropagation();
          if (closeModal) closeModal();
        }}
      />
      <Logo className={styles.logoHeart} onlyHeart width={50} />
    </div>
  );
};

export default Step3;
