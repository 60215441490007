/* eslint-disable camelcase */
// eslint-disable-next-line import/no-cycle
import HTTPKit from '../HTTPKit';

interface MemberListRequest {
  page?: number;
  name?: string;
  region?: number[];
  competences?: number[] | number;
  expertise?: number[] | number;
  top_latitude?: number;
  top_longitude?: number;
  bottom_latitude?: number;
  bottom_longitude?: number;
  page_size?: number;
}

const MemberEndpoints = {
  patchMyMember: (
    payload: Record<string, any>,
    queryParams?: Record<string, any>,
  ) => {
    const url = '/api/v1/me/member/';
    return HTTPKit.patch(url, payload, queryParams);
  },
  createContactRequest: (formData: FormData) => {
    const url = `/api/v1/request-contact/`;
    return HTTPKit.post(url, formData);
  },
  levels: {
    getList: () => {
      const url = '/api/v1/members/levels/';
      return HTTPKit.get(url);
    },
  },
  memberTree: {
    getList: () => {
      const url = 'api/v1/me/member-tree/';
      return HTTPKit.get(url);
    },
  },
};

export default MemberEndpoints;
