import React, { useContext } from 'react';

import UserContext from 'contexts/UserContext';
import Link from 'next/link';
import RouteKit from 'services/RouteKit';

const UserTile = () => {
  const { user } = useContext(UserContext);

  return (
    <div className="bg-gray-50 p-2">
      <Link
        href={RouteKit.settings.me.href}
        as={RouteKit.settings.me.as}
        style={{ textDecoration: 'none' }}
      >
        <p className="mb-0 font-weight-bold text-gray-700">
          {user?.firstName} {user?.lastName}
        </p>
        <p className="mb-0 text-gray-500">{user?.member?.name}</p>
      </Link>
    </div>
  );
};

export default UserTile;
