import RouteKit from 'services/RouteKit';

import type { MenuLink } from './types';
import { Feature } from '../../services/constants/index';

const NavLinks: MenuLink[] = [
  {
    fallbackTitle: 'VetFamily',
    kind: 'DROPDOWN',
    links: [
      {
        alwaysOn: false,
        fallbackTitle: 'Services',
        id: Feature.SERVICES,
        kind: 'LINK',
        path: RouteKit.services.list,
        title: 'navigation.links.services',
      },
      {
        alwaysOn: false,
        title: 'navigation.links.contactUs',
        id: Feature.CONTACT,
        kind: 'LINK',
        fallbackTitle: 'Contact us',
        path: RouteKit.contacts,
      },
    ],
    title: 'navigation.dropdown.vetFamily',
  },
  {
    fallbackTitle: 'Partners',
    kind: 'DROPDOWN',
    links: [
      {
        alwaysOn: false,
        title: 'navigation.links.partners',
        id: Feature.PARTNERS,
        kind: 'LINK',
        fallbackTitle: 'Presentations',
        path: RouteKit.partners.index,
      },
      {
        alwaysOn: false,
        title: 'navigation.links.campaigns',
        id: Feature.CAMPAIGNS,
        kind: 'LINK',
        fallbackTitle: 'Campaigns',
        path: RouteKit.campaigns.list,
      },
    ],
    title: 'navigation.dropdown.partners',
  },
  {
    fallbackTitle: 'Community',
    kind: 'DROPDOWN',
    links: [
      {
        alwaysOn: false,
        title: 'navigation.links.events',
        id: Feature.EVENTS,
        kind: 'LINK',
        fallbackTitle: 'Events',
        path: RouteKit.events.list,
      },
      {
        alwaysOn: false,
        title: 'navigation.links.forum',
        id: Feature.FORUM,
        kind: 'LINK',
        fallbackTitle: 'Forum',
        path: RouteKit.forum.index(),
      },
      {
        alwaysOn: false,
        title: 'navigation.links.directads',
        id: Feature.DIRECT_ADS,
        kind: 'LINK',
        fallbackTitle: 'Direct Ads',
        path: RouteKit.directads.list,
      },
    ],
    title: 'navigation.dropdown.community',
  },
  {
    fallbackTitle: 'Reports',
    kind: 'LINK',
    alwaysOn: false,
    title: 'navigation.links.reports',
    id: Feature.REPORTS,
    path: RouteKit.reports.index,
    probableLinks: [
      { id: Feature.REPORTS, path: RouteKit.reports.index },
      { id: Feature.SPEND_SAVINGS, path: RouteKit.reports.SpendAndSavings },
    ],
  },
  {
    fallbackTitle: 'Procurement',
    kind: 'DROPDOWN',
    links: [
      {
        alwaysOn: false,
        title: 'commitments.tabs.title.index',
        id: Feature.COMMITMENTS,
        kind: 'LINK',
        fallbackTitle: 'Commitments',
        path: RouteKit.commitments.index,
      },
      {
        alwaysOn: false,
        title: 'commitments.tabs.title.tracker',
        id: Feature.COMMITMENTS,
        kind: 'LINK',
        fallbackTitle: 'Track',
        path: RouteKit.commitments.tracker,
      },
    ],
    title: 'navigation.links.procurement',
  },
  {
    fallbackTitle: 'Knowledge',
    kind: 'DROPDOWN',
    links: [
      {
        alwaysOn: false,
        title: 'navigation.links.news',
        id: Feature.NEWS,
        kind: 'LINK',
        fallbackTitle: 'News',
        path: RouteKit.news.list,
      },
      {
        alwaysOn: false,
        title: 'navigation.links.wiki',
        id: Feature.WIKI,
        kind: 'LINK',
        fallbackTitle: 'Wiki',
        path: RouteKit.wiki.list,
      },
      {
        alwaysOn: false,
        title: 'navigation.links.handbooks',
        id: Feature.HANDBOOKS,
        kind: 'LINK',
        fallbackTitle: 'Handbooks',
        path: RouteKit.handbooks.list,
      },
      {
        alwaysOn: false,
        title: 'digest.title',
        id: Feature.DIGEST,
        kind: 'LINK',
        fallbackTitle: 'Digest',
        path: RouteKit.digest.list,
        staffOnly: true,
      },
    ],
    title: 'navigation.dropdown.knowledge',
  },
  {
    fallbackTitle: 'VetData',
    kind: 'DROPDOWN',
    links: [
      {
        alwaysOn: false,
        title: 'navigation.links.vetDataSurvey',
        id: Feature.VETDATA,
        kind: 'LINK',
        fallbackTitle: 'VetData',
        path: RouteKit.vetData.list,
      },
      {
        alwaysOn: false,
        title: 'navigation.links.vetDataMemberDashboard',
        id: Feature.VETDATA,
        kind: 'LINK',
        fallbackTitle: 'Member Dashboard',
        path: RouteKit.vetData.memberDashboard,
      },
      {
        alwaysOn: false,
        title: 'navigation.links.vetDataMemberReport',
        id: Feature.VETDATA,
        kind: 'LINK',
        fallbackTitle: 'Report',
        path: RouteKit.vetData.memberReport,
      },
      {
        alwaysOn: false,
        title: 'navigation.links.vetDataMembersReport',
        id: Feature.VETDATA,
        kind: 'LINK',
        fallbackTitle: 'Members Report',
        path: RouteKit.vetData.membersReport,
        staffOnly: true,
      },
      {
        alwaysOn: false,
        title: 'navigation.links.vetDataSuppliersDashboard',
        id: Feature.VETDATA,
        kind: 'LINK',
        fallbackTitle: 'Suppliers Dashboard',
        path: RouteKit.vetData.suppliersDashboard,
        staffOnly: true,
      },
      {
        alwaysOn: false,
        title: 'navigation.links.vetDataSuppliersReports',
        id: Feature.VETDATA,
        kind: 'LINK',
        fallbackTitle: 'Suppliers Reports',
        path: RouteKit.vetData.suppliersReports,
        staffOnly: true,
      },
    ],
    title: 'navigation.links.vetData',
  },
];

export const userMenuLinks = {};
export default NavLinks;
