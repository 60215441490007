import React from 'react';

import classNames from 'classnames';
import Link from 'next/link';
import { useTranslation } from 'react-i18next';

import styles from '../NavMenu.module.scss';
import type { DropdownMenuItemProps } from '../types';

const DropdownMenuItem = ({ menuItem }: DropdownMenuItemProps) => {
  const { t } = useTranslation();
  return (
    <li
      className={classNames(styles.WrapText, 'btn')}
      key={menuItem.fallbackTitle}
    >
      <div
        className={classNames(
          'mx-2',
          'dropdown',
          styles.dropdown,
          styles.changeFontSize,
        )}
      >
        <a className={`dropdown-toggle text-wrap pb-4 ${styles.hideCaret} `}>
          {t(menuItem.title)}
        </a>
        <ul
          className={`list-unstyled dropdown-menu shadow-sm border-0 mt-3 ${styles.dropdownMenu}`}
        >
          {menuItem.links.map((dropdownItem: any) => (
            <li className="" key={dropdownItem.fallbackTitle}>
              <Link
                href={dropdownItem.path.as}
                as={dropdownItem.path.as}
                className={`dropdown-item ${styles.dropdownItem} `}
              >
                {t(dropdownItem.title)}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </li>
  );
};

export default DropdownMenuItem;
