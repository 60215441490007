/* eslint-disable import/no-cycle */
import amadeo from './endpoints/amadeo';
import auth from './endpoints/auth';
import bEngaged from './endpoints/bEngaged';
import campaigns from './endpoints/campaigns';
import dev from './endpoints/dev';
import digest from './endpoints/digest';
import directAds from './endpoints/directAds';
import events from './endpoints/events';
import featureFlags from './endpoints/featureFlags';
import forum from './endpoints/forum';
import fea from './endpoints/frontEndAdmin';
import members from './endpoints/member';
import news from './endpoints/news';
import partners from './endpoints/partners';
import reports from './endpoints/reports';
import services from './endpoints/services';
import staff from './endpoints/staff';
import stream from './endpoints/stream';
import tags from './endpoints/tags';
import users from './endpoints/users';
import wiki from './endpoints/wiki';

const APIKit = {
  amadeo,
  auth,
  campaigns,
  dev,
  digest,
  directAds,
  events,
  featureFlags,
  forum,
  users,
  members,
  news,
  partners,
  reports,
  services,
  staff,
  stream,
  wiki: wiki.articles,
  wikiHandbooks: wiki.handbooks,
  wikiChapters: wiki.chapters,
  wikiSections: wiki.sections,
  tags,
  fea,
  bEngaged,
};

export default APIKit;
