import React from 'react';

import { Field, FieldProps } from 'formik';
import Select, { GroupBase, StylesConfig } from 'react-select';
import { renameKeys } from 'services/helpers';

interface SelectInputProps {
  label?: string | JSX.Element;
  name?: string;
  required?: boolean;
  disabled?: boolean;
  className?: string;
  multiple?: boolean;
  collection: { id: number | string; name: string }[];
  error?: string | string[] | never[] | undefined;
  touched?: boolean | never[] | undefined;
  updateState?: (event) => void;
  modal?: boolean;
  customStyles?: StylesConfig<{}, boolean, GroupBase<{}>> | undefined;
}

interface Option {
  label: string;
  value: string;
  externalId?: string;
}

const SelectInput = ({
  name,
  label,
  required,
  className,
  collection,
  multiple = false,
  error,
  touched,
  updateState,
  modal = false,
  customStyles,
  disabled,
}: SelectInputProps): JSX.Element => {
  const options = collection.map(c =>
    renameKeys({ id: 'value', name: 'label' }, c),
  );

  const getValues = field => {
    if (!options) return '';

    if (multiple && field.value) {
      return options.filter(option =>
        field.value.includes((option as Option).value),
      );
    }

    return options.find(option => (option as Option).value === field.value);
  };

  const handleOnChange = (option, form, field) => {
    let value;
    if (option) {
      if (multiple) {
        value = (option as Option[]).map((item: Option) => item.value);
      } else {
        value = (option as Option).value;
      }
    } else {
      value = multiple ? [] : null;
    }

    form.setFieldValue(field.name, value);

    if (updateState) updateState({ target: { name: field.name, value } });
  };

  const defaultCustomStyles = {
    control: provided => ({
      ...provided,
      border: '1px solid #919191',
    }),
    placeholder: provided => ({
      ...provided,
      color: '#3b3b3b',
    }),
    dropdownIndicator: provided => ({
      ...provided,
      color: '#3b3b3b',
    }),
    indicatorSeparator: provided => ({
      ...provided,
      backgroundColor: '#919191',
    }),
  };

  return (
    <div className={`form-group${className ? ` ${className}` : ''}`}>
      {label && (
        <label>
          {label}
          {required && (
            <span className="text-danger" aria-hidden="true">
              {' '}
              *
            </span>
          )}
        </label>
      )}
      <Field name={name}>
        {({ field, form }: FieldProps): JSX.Element => (
          <Select
            name={field.name}
            value={getValues(field)}
            onChange={option => handleOnChange(option, form, field)}
            options={options}
            isMulti={multiple}
            styles={customStyles || defaultCustomStyles}
            theme={theme => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: '#c1cac4',
                primary: '#829488',
              },
            })}
            menuPortalTarget={modal ? undefined : document.body}
            getOptionValue={option =>
              (option as Option).externalId || (option as Option).value
            }
            isDisabled={disabled}
          />
        )}
      </Field>
      <div
        className={`invalid-feedback ${error ? 'd-block' : ''}`}
        id={`${name}FieldError`}
      >
        {touched && error}
      </div>
    </div>
  );
};

export default SelectInput;
