import React from 'react';

import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';

import styles from './MobileMenu.module.scss';
import type { LinkType, MenuLink, MobileMenuType } from '../types';

const MobileLinkList = ({ closeMenu, linkList }: MobileMenuType) => {
  const { t } = useTranslation();
  const router = useRouter();
  const isLink = (item: any): item is LinkType => !!item.path;
  return (
    <nav>
      <ul className="list-unstyled d-flex flex-column mb-5">
        {linkList?.map((link: MenuLink) => {
          if (!isLink(link)) return null;
          const {
            fallbackTitle,
            title,
            path: { as },
          } = link;
          const active = router.pathname === link.path?.as;
          return (
            <li key={title} onClick={closeMenu}>
              <Link
                href={as}
                as={as}
                passHref
                className={`${styles.menuLink} ${
                  active ? `${styles.active}` : ''
                }`}
              >
                {active && (
                  <span className="sr-only">
                    {t('navigation.currentPage')}:
                  </span>
                )}
                {t(title, fallbackTitle)}
              </Link>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default MobileLinkList;
