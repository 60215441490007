import sluggify from 'services/sluggify';

const Handbook = {
  list: {
    as: '/handbooks',
    href: '/handbooks',
  },
  detail: (
    id: number,
    title?: string,
    chapterIndex?: number,
    sectionIndex?: number,
  ) => ({
    as: `/handbooks/${id}${title ? `-${sluggify(title)}` : ''}${
      chapterIndex ? `#chapter-${chapterIndex}` : ''
    }${sectionIndex ? `-section-${sectionIndex}` : ''}`,
    href: '/handbooks/[id]',
  }),
  chapter: (id: number, handbookId: number) => ({
    as: `/handbooks/${handbookId}#chapter-${id}`,
    href: '/handbooks/[id]#chapter-[id]',
  }),
  section: (id: number, handbookId: number, chapterId: number) => ({
    as: `/handbooks/${handbookId}#chapter-${chapterId}-section-${id}`,
    href: '/handbooks/[id]#chapter-[id]-section[i]',
  }),
  create: { as: '/handbooks/admin', href: '/handbooks/admin' },
  update: (id: number) => ({
    as: `/handbooks/admin/${id}`,
    href: '/handbooks/admin/[id]',
  }),
  chapters: {
    create: {
      as: '/handbooks/chapter/admin',
      href: '/handbooks/chapter/admin',
    },
    update: (id: number) => ({
      as: `/handbooks/chapter/admin/${id}`,
      href: '/handbooks/chapter/admin/[id]',
    }),
  },
  sections: {
    create: {
      as: '/handbooks/section/admin',
      href: '/handbooks/section/admin',
    },
    update: (id: number) => ({
      as: `/handbooks/section/admin/${id}`,
      href: '/handbooks/section/admin/[id]',
    }),
  },
};

export default Handbook;
