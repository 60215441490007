import React from 'react';

import classNames from 'classnames';

import styles from './ToggleButton.module.scss';

export type toggleButtonType = {
  id: string;
  label: string;
  isChecked?: boolean;
  isDisabled?: boolean;
  handleChange: (e?: any) => void | void;
};

const ToggleButton = ({
  id,
  label,
  handleChange,
  isChecked = false,
  isDisabled = false,
}: toggleButtonType): JSX.Element => (
  <li
    className={classNames(
      styles.devControlSwitch,
      'custom-control custom-switch',
    )}
  >
    <input
      type="checkbox"
      className="custom-control-input"
      id={id}
      checked={isChecked}
      onChange={handleChange}
      disabled={isDisabled}
    />
    <label className="custom-control-label" htmlFor={id}>
      {label}
    </label>
  </li>
);

export default ToggleButton;
