import React, { memo, useContext, useEffect, useState } from 'react';

import UserContext from 'contexts/UserContext';
import Link from 'next/link';
import { FeatureFlagContext } from 'pages/_app';
import {
  queries as SWRNotificationQueries,
  mutations as SWRNotificationMutations,
  GetListProps,
} from 'services/APIKit/SWR/notifications';
import { Notification } from 'services/APIKit/types';
import RouteKit from 'services/RouteKit';
import useSWR from 'swr';
import useSWRMutation from 'swr/mutation';

import styles from './NotificationList.module.scss';
import NotificationListItem from './NotificationListItem';
import type { NotificationListProps } from '../types';
import hasAccess from '../utils/parseNotifications';

const {
  delete: { key: DELETE_KEY, mutation: deleteMutation },
  markAsRead: { key: MARK_AS_READ_KEY, mutation: markAsRead },
} = SWRNotificationMutations;
const {
  getList: { key: NOTIFICATION_KEY, fetcher: fetchNotificationsSWR },
} = SWRNotificationQueries;

const NotificationList = ({
  menu = false,
  setUnread,
  setNotifications,
  num,
  asLink,
  mutateNum,
  category,
}: NotificationListProps) => {
  const [initialMutation, setInitialMutation] = useState(mutateNum);
  const featureFlags = useContext(FeatureFlagContext);

  const SWRNotification: GetListProps = [NOTIFICATION_KEY, { pageSize: 20 }];

  const { user } = useContext(UserContext);
  const { data: ntf, mutate } = useSWR(SWRNotification, fetchNotificationsSWR);

  const { results: notificationsList, count: numNotifications } = ntf;

  let notifications = notificationsList.filter((notification: Notification) =>
    hasAccess({ notification, user, featureFlags }),
  );

  const sn = (n: number): void => {
    if (setNotifications !== undefined) {
      setNotifications(n);
    }
  };

  if (category) {
    notifications = notifications.filter(
      ({ description }) => description === category,
    );
  }
  if (num) {
    notifications = notifications.slice(0, num);
  }

  const del = useSWRMutation(DELETE_KEY, deleteMutation);
  const {
    trigger: doDelete,
    isMutating: isDeleting,
    error: errorDeleting,
  } = del;

  const read = useSWRMutation(MARK_AS_READ_KEY, markAsRead);
  const { trigger: doRead } = read;

  const readNotification = async (id: number) => {
    await doRead(id);
    mutate();
  };

  const deleteNotification = async (e: any, id: number) => {
    e.preventDefault();
    e.stopPropagation();
    await doDelete(id);
    mutate();
  };

  useEffect(() => {
    if (setUnread !== undefined) {
      setUnread(notifications.some(n => n.unread));
    }
  }, [notifications]);

  useEffect(() => {
    if (category) {
      sn(notifications.length);
    } else {
      sn(numNotifications);
    }
  }, [numNotifications, category]);

  useEffect(() => {
    if (mutateNum !== initialMutation) {
      setInitialMutation(mutateNum);
      mutate();
    }
  }, [mutateNum]);

  if (asLink) {
    return (
      <Link
        href={RouteKit.notifications.href}
        as={RouteKit.notifications.as}
        className={styles.mobileNotification}
      >
        <i className="fas fa-bell text-gray-600" />
        {notifications.some(n => n.unread) && (
          <div className={styles.notificationBubble} />
        )}
      </Link>
    );
  }

  return (
    <ul className="list-unstyled">
      {notifications.map((notification: Notification) => (
        <li key={notification.id}>
          <NotificationListItem
            readNotification={readNotification}
            deleteNotification={deleteNotification}
            notification={notification}
            menu={menu}
          />
        </li>
      ))}
    </ul>
  );
};
const arePropsEqual = (
  prev: NotificationListProps,
  next: NotificationListProps,
) => {
  const changes = Object.keys(prev).filter(k => prev[k] !== next[k]);
  return changes.length === 0;
};
export default memo(NotificationList, arePropsEqual);
