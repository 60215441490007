import sluggify from 'services/sluggify';

const Wiki = {
  list: {
    as: '/wiki/articles',
    href: '/wiki/articles',
  },
  detail: (id: number, title?: string) => ({
    as: `/wiki/articles/${id}${title ? `-${sluggify(title)}` : ''}`,
    href: '/wiki/articles/[id]',
  }),
  create: { as: '/wiki/articles/admin', href: '/wiki/articles/admin' },
  update: (id: number) => ({
    as: `/wiki/articles/admin/${id}`,
    href: '/wiki/articles/admin/[id]',
  }),
};

export default Wiki;
