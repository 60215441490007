import axios from 'axios';
import { getRefreshTokenFromCookie, login2 } from 'services/withAuth';

// eslint-disable-next-line import/no-cycle
import HTTPKit from '../HTTPKit';
import { authEndpoint, tokenRefreshEndpoint } from '../interceptors/helpers';

const AuthEndpoints = {
  sendMagicLoginLink: (email: string) => {
    const url = '/api/v1/send-magic-login-link/';
    return HTTPKit.post(url, { email: email.toLowerCase() });
  },
  createToken: ({
    email,
    password,
    nextQuery,
  }: {
    email: string;
    password: string;
    nextQuery?: string;
  }) => {
    const url = authEndpoint;
    return HTTPKit.post(url, {
      email: email.toLowerCase(),
      password,
    }).then(response => {
      if (response.status === 200) {
        const { access, refresh } = response.data;
        login2(access, refresh, nextQuery);
      } else if (response.status === 400) {
        const { nonFieldErrors } = response.data;
        return nonFieldErrors;
      }
      return response;
    });
  },
  createAccessAndRefreshToken: ({
    email,
    password,
    nextQuery,
  }: {
    email: string;
    password: string;
    nextQuery?: string;
  }) => {
    const url = authEndpoint;
    return HTTPKit.post(url, { email, password }).then(response => {
      if (response.status === 200) {
        const { access, refresh } = response.data;
        login2(access, refresh, nextQuery);
      } else if (response.status === 400) {
        const { detail } = response.data;
        return detail;
      }
      return response;
    });
  },
  verifyToken: async (token: string) => {
    const url = '/api/v1/auth/api-token-verify/';
    return HTTPKit.post(url, { token });
  },
  resendActivationEmail: (email: string) => {
    // TODO May 18, 2021: Check if this one can be removed
    const url = '/api/v1/auth/register/resend_activation_email/';
    return HTTPKit.post(url, { email: email.toLowerCase() });
  },
  resetPassword: (email: string) => {
    const url = '/api/v1/auth/users/reset_password/';
    return HTTPKit.post(url, { email: email.toLowerCase() });
  },
  resetPasswordConfirm: (payload: {
    newPassword: string;
    token: string;
    uid: string;
  }) => {
    const url = '/api/v1/auth/users/reset_password_confirm/';
    return HTTPKit.post(url, payload);
  },
  refreshTokens: async (refreshToken?: string) => {
    const refresh = refreshToken || getRefreshTokenFromCookie();
    const client = axios.create({
      baseURL: process.env.API_BASE_URL,
      headers: { 'Content-Type': 'application/json' },
    });
    return client({
      url: tokenRefreshEndpoint,
      data: { refresh },
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
    });
  },
};
export default AuthEndpoints;
