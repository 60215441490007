import Cookies from 'js-cookie';

// eslint-disable-next-line import/no-cycle
import { COOKIES } from './helpers';

const requestInterceptor = options => {
  const config = options;
  const accessToken = Cookies.get(COOKIES.ACCESS_TOKEN);
  if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`;
  return config;
};

export default requestInterceptor;
