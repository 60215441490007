import React, { useEffect, useState } from 'react';

import { Filter } from 'services/APIKit/types';

/**
 * This hook allows you to detect clicks outside of a specified element. In the
 * example below we use it to close a modal when any element outside of the
 * modal is clicked.
 *
 * @param ref - A reference to an HTML Element
 * @param handler - The function you want to run when a click outside the ref
 * is detected.
 */
export function useOnClickOutside(
  ref: React.MutableRefObject<HTMLElement | null>,
  handler: Function,
) {
  useEffect(
    () => {
      const listener = (event: any) => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }

        handler(event);
      };

      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', listener);

      return () => {
        document.removeEventListener('mousedown', listener);
        document.removeEventListener('touchstart', listener);
      };
    }, // ... callback/cleanup to run every render. It's not a big deal ... // ... function on every render that will cause this effect ... // It's worth noting that because passed in handler is a new ... // Add ref and handler to effect dependencies
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [ref, handler],
  );
}

interface UseInfiniteScroll {
  setIsLoading: Function;
  getList: Function;
  setListResponse: Function;
  listResponse: any;
  isLoading: boolean;
  setCurrentPage: Function;
  queries: Record<any, any>;
}

export function useInfiniteScroll({
  setIsLoading,
  getList,
  setListResponse,
  listResponse,
  isLoading,
  setCurrentPage,
  queries,
}: UseInfiniteScroll): void {
  useEffect(() => {
    function isAtBottom(): boolean {
      return window.innerHeight + window.scrollY >= document.body.offsetHeight;
    }

    async function fetch(prevPage: number): Promise<void> {
      setIsLoading(true);
      const page = prevPage + 1;
      const { data: response } = await getList({ page, ...queries });
      if (response) {
        setListResponse((prevResponse: any) => ({
          ...response,
          results: [...prevResponse.results, ...response.results],
        }));
      }
      setIsLoading(false);
    }

    window.onscroll = async function loadMore(): Promise<void> {
      const hasMore = !!listResponse.next;

      // Bail if we're already loading data or if there are no more pages.
      if (isLoading || !hasMore) {
        return;
      }

      if (isAtBottom()) {
        setCurrentPage((prevPage: number) => {
          fetch(prevPage);
          return prevPage + 1;
        });
      }
    };

    // Prevents the user from having to scroll up and back down to load more content.
    // If the user stays at the bottom, this interval will catch it and load more.
    const loadMoreInterval = setInterval(() => {
      const hasMore = !!listResponse.next;
      if (isLoading || !hasMore) {
        return;
      }

      if (isAtBottom()) {
        setCurrentPage((prevPage: number) => {
          fetch(prevPage);
          return prevPage + 1;
        });
      }
    }, 1000);

    return (): void => {
      window.onscroll = null;
      clearInterval(loadMoreInterval);
    };
  }, [listResponse.next, isLoading]);
}

// Simple reusable hook for opened/closed toggle. For dropdowns etc...
export const useOpenClose = (): [
  boolean,
  () => void,
  () => void,
  () => void,
] => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const open = (): void => setIsOpen(true);
  const close = (): void => setIsOpen(false);
  const toggle = (): void => setIsOpen(!isOpen);

  return [isOpen, open, close, toggle];
};

export const useSearchAndFilter = () => {
  type StatefulFilterOption = Filter & {
    isSelected: boolean;
  };
  const [searchTerm, setSearchTerm] = useState('');
  const [filters, setFilters] = useState<StatefulFilterOption[]>([]);

  const externalSetFilters = (filterOptions: StatefulFilterOption[]) => {
    setFilters(filterOptions);
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const clearSearch = () => {
    setSearchTerm('');
  };

  const clearFilters = () => {
    setFilters(filters.map(filter => ({ ...filter, isSelected: false })));
  };

  const toggleFilter = (filter: Filter) => {
    const index = filters.findIndex(({ id }) => id === filter.id);
    const newState = [...filters];
    if (index !== -1) newState[index].isSelected = !newState[index].isSelected;
    setFilters(newState);
  };

  return {
    searchTerm,
    setSearchTerm,
    handleSearch,
    filters,
    setFilters: externalSetFilters,
    toggleFilter,
    clearFilters,
    clearSearch,
  };
};

type useStatusType = {
  statuses: number[];
  toggleStatus: (id: number) => void;
};
