import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { getEdgeData, EdgeDataInterface } from 'services/EdgeConfig';

import styles from './GlobalInfoMessage.module.scss';

const GlobalInfoMessage = () => {
  const [edgeItems, setEdgeItems] = useState<EdgeDataInterface | null>(null);
  const [dismissed, dismiss] = useState(false);
  const { i18n, t } = useTranslation();
  moment.locale(i18n.language);

  const { showMessage, eventFromTime, eventToTime } =
    edgeItems?.infoMessage || {};
  const day = moment(eventFromTime).format('dddd, MMMM DD');
  const from = moment(eventFromTime).format('HH');
  const to = moment(eventToTime).format('HH');

  useEffect(() => {
    getEdgeData().then(setEdgeItems);
    const intervall = setInterval(
      () => {
        getEdgeData().then(setEdgeItems);
      },
      1000 * 60 * 5,
    );
    return () => clearInterval(intervall);
  }, []);

  return dismissed || !showMessage ? null : (
    <div className="alert alert-warning fade show mb-0" role="alert">
      <div className="container-fluid">
        <div className={classNames('container', styles.wrapper)}>
          <i
            className={classNames(
              'fas',
              'fa-exclamation-triangle',
              styles.icon,
            )}
          />
          <span className={styles.message}>
            {t('globalInfoMessage', { day, from, to })}
          </span>
          <button
            type="button"
            className={styles.close}
            data-dismiss="alert"
            aria-label="Close"
            onClick={() => dismiss(true)}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default GlobalInfoMessage;
