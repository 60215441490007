import { datadogRum } from '@datadog/browser-rum';

import type { User } from './APIKit/types';

type LogErrorType = { error: any; context?: any };
export const logError = ({ error, context }: LogErrorType) => {
  if (context) {
    datadogRum.addError(error, context);
  } else {
    datadogRum.addError(error);
  }
};

const init = () => {
  datadogRum.init({
    applicationId: process.env.DATADOG_APPLICATION_ID as string,
    clientToken: process.env.DATADOG_CLIENT_TOKEN as string,
    site: 'datadoghq.eu',
    service: 'vf-member-portal',
    env: process.env.APP_STAGE,
    version: `${process.env.APP_STAGE}-${process.env.NEXT_PUBLIC_COMMIT_SHA}`,
    allowedTracingUrls: [process.env.API_BASE_URL as string],
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  });
};

export const updateUserTracking = (user: User) => {
  /*
    For staff we track more data to be able to check anomalies and debug errors,
    for members we only track the id.
  */
  const staff = user.isStaff || user.isSuperuser;
  const data: import('@datadog/browser-core').User = {
    id: user.id.toString(),
    name: staff ? `${user.firstName} ${user.lastName}` : undefined,
    email: staff ? user.email : undefined,
    isStaff: staff,
    language: user.language,
  };

  datadogRum.setUser(data);
};

export default init;
