import React, { Component } from 'react';

import ErrorHTTP from 'components/ErrorHTTP/ErrorHTTP';
import { logError } from 'services/datadog';

type ErrorBoundaryProps = {
  children: JSX.Element;
};
type ErrorBoundaryState = {
  error: null | number;
};

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  static getDerivedStateFromError(error: any): any {
    return { error };
  }

  componentDidCatch(error: any, info: any) {
    // Docs at https://docs.datadoghq.com/real_user_monitoring/browser/collecting_browser_errors/?tab=npm#collect-errors-manually
    const renderingError = new Error(error.message);
    renderingError.name = `ReactRenderingError`;
    renderingError.stack = info.componentStack;
    renderingError.cause = error;
    this.setState({ error: error.code || error.status || 500 });

    logError({ error: renderingError });
  }

  render(): JSX.Element {
    const { error } = this.state;
    const { children } = this.props;

    return error ? <ErrorHTTP code={error} /> : children;
  }
}

export default ErrorBoundary;
