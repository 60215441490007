/* eslint-disable no-underscore-dangle */
import { AxiosError, AxiosRequestConfig } from 'axios';
import { isClient } from 'services/serverOrClient';
import { getRefreshTokenFromCookie } from 'services/withAuth';

// eslint-disable-next-line import/no-cycle
import {
  getRefreshedTokens,
  retryRequest,
  tokenRefreshEndpoint,
  queueFailedRequest,
  FailedQueueObject,
  resolveRequest,
  rejectRequest,
  authEndpoint,
} from './helpers';
import HTTPKit from '../HTTPKit';
// eslint-disable-next-line import/no-cycle

let currentlyRefreshing = false;
let failedQueue: FailedQueueObject[] = [];

const clearQueue = () => {
  failedQueue = [];
};

const responseErrorInterceptor = (
  error: AxiosError,
): Promise<any | AxiosError> => {
  const request: AxiosRequestConfig & { _retry?: boolean } = error.config;
  const status = error.response?.status;

  // If the failed request was a token-refresh atempt, do not proceed
  if (
    isClient() &&
    (request.url?.endsWith(tokenRefreshEndpoint) ||
      request.url?.endsWith(authEndpoint))
  ) {
    return Promise.reject(error);
  }

  if (status === 401 && !request._retry) {
    if (currentlyRefreshing) {
      return new Promise(queueFailedRequest(failedQueue))
        .then(retryRequest(request))
        .catch(err => Promise.reject(err));
    }

    request._retry = true;
    currentlyRefreshing = true;

    return new Promise((resolve, reject) => {
      const refreshToken = getRefreshTokenFromCookie();
      getRefreshedTokens(refreshToken)
        .then(accessToken => {
          if (request.headers) {
            request.headers.Authorization = `Bearer ${accessToken}`;
          }
          failedQueue.forEach(resolveRequest(accessToken!));
          clearQueue();
          resolve(HTTPKit.client(request));
        })
        .catch(err => {
          failedQueue.forEach(rejectRequest(err));
          clearQueue();
          reject(err);
        })
        .then(() => {
          currentlyRefreshing = false;
        });
    });
  }

  return Promise.reject(error);
};

export default responseErrorInterceptor;
