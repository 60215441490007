import qs from 'query-string';

const Forum = {
  index: (page = 1) => ({
    as: `/forum${page !== 1 ? `?page=${page}` : ''}`,
    href: `/forum?page=${page}`,
  }),
  thread: ({
    id,
    options: { reply, quoteId },
  }: {
    id: number | string;
    options: {
      reply?: boolean;
      quoteId?: number;
    };
  }) => {
    const queryString = qs.stringify({ reply, quoteId });
    return {
      as: `/forum/${id}?${queryString}`,
      href: `/forum/[id]?${queryString}`,
    };
  },
};

export default Forum;
