import React, { useContext } from 'react';

import Tooltip from '@mui/material/Tooltip';
import classNames from 'classnames';
import UserContext from 'contexts/UserContext';
import moment from 'moment';
import Link from 'next/link';
import { useTranslation } from 'react-i18next';

import entityDetails from './entityDetails';
import styles from '../Notifications.module.scss';
import type { NotificationListItemProps } from '../types';

const NotificationListItem = ({
  menu = false,
  readNotification,
  deleteNotification,
  notification: { id, actor, verb, unread, timestamp, description },
}: NotificationListItemProps) => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  if (user?.language) {
    moment.locale(user.language);
  }
  const { icon, notificationText, path } = entityDetails(
    description,
    verb,
    actor.title,
    actor.id,
  );

  return (
    <Link
      href={path.href}
      as={path.as}
      onClick={() => {
        if (readNotification) readNotification(id);
      }}
      className={classNames(styles.notificationCard, {
        [styles.isMenu]: menu,
        [styles.notMenu]: !menu,
        [styles.unreadNotification]: unread,
      })}
    >
      <div className={styles.content}>
        <i
          className={classNames('fas', icon, 'fa-2x', styles.notificationIcon)}
        />
        <div>
          <h4 className={styles.title}>{actor.title}</h4>
          <p className={styles.body}>
            {t(notificationText)} {moment(timestamp).fromNow()}
          </p>
        </div>
      </div>
      {!menu && (
        <div style={{ zIndex: 1 }} className="d-block">
          <Tooltip
            enterTouchDelay={0}
            title={t('notifications.dismiss')}
            placement="top"
          >
            <button
              type="button"
              className="btn"
              onClick={e => {
                if (deleteNotification) {
                  deleteNotification(e, id);
                }
              }}
            >
              <i className="fas fa-close text-align-right text-green-300" />
            </button>
          </Tooltip>
        </div>
      )}
    </Link>
  );
};
export default NotificationListItem;
