import React, { useContext } from 'react';

import classNames from 'classnames';
import UserContext from 'contexts/UserContext';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import APIKit from 'services/APIKit';
import { mutations as SWRMutateTracking } from 'services/APIKit/SWR/tracking';
import { LanguageType } from 'services/APIKit/types';
import { logError } from 'services/datadog';
import { languageFlags } from 'services/flags';
import { LOCALES } from 'services/locale';
import useSWRMutation from 'swr/mutation';

import styles from './LanguageSwitcher.module.scss';

const {
  trackClick: { key: MUTATE_CLICK, mutate: trackClick },
} = SWRMutateTracking;

type LanguageSwitcherProps = {
  context?: string;
  componentClass?: string;
  itemClass?: string;
  selectedClass?: string;
  iconClass?: string;
  languages?: LanguageType[];
  displayFlags?: boolean;
  isLoggedOut?: boolean;
};

const LanguageSwitcher = ({
  context = 'menu',
  componentClass,
  itemClass,
  selectedClass,
  iconClass,
  languages: langs,
  displayFlags,
  isLoggedOut,
}: LanguageSwitcherProps) => {
  const { t, i18n } = useTranslation();
  const { user, refreshUser } = useContext(UserContext);
  const router = useRouter();
  const { trigger: track, isMutating: isTracking } = useSWRMutation(
    MUTATE_CLICK,
    trackClick,
  );
  const languages = langs || user?.member?.country.languages || [];
  const currentLang = i18n.language;

  const changeLanguage = async ({
    id: language,
    code: locale,
  }: LanguageType) => {
    const oldLang = user?.language?.toString();
    if (!isLoggedOut) {
      await APIKit.dev.updateLocale({ language });
    }
    if (!i18n.store.data[locale]) {
      await i18n.reloadResources(locale);
    }
    i18n
      .changeLanguage(locale)
      .then(() => {
        if (!isLoggedOut) {
          // re-fetch the user for the short cache after updating language
          refreshUser();
          track({
            actionDetails: 'language switcher',
            data: { from: oldLang, to: locale },
            context,
          });
        }
        if (locale === LOCALES.TEST) {
          // do a hard reload to ensure we are using hte trans keys (only dev)
          router.reload();
        }
      })
      .catch(err => {
        // reload if we caught any error
        logError({ error: err });
        router.reload();
      });
  };

  return (
    <ul className={componentClass || styles.languageList}>
      {languages.map(language => (
        <li key={`language-switcher-${language.id}`}>
          <button
            onClick={() => {
              changeLanguage(language);
            }}
            type="button"
            disabled={currentLang === language.code}
            className={classNames(itemClass || styles.listItemButton, {
              [styles.selected]:
                !selectedClass && currentLang === language.code,
              [selectedClass as string]:
                selectedClass && currentLang === language.code,
            })}
          >
            <span className={iconClass || styles.flagIcon}>
              {displayFlags ? (
                languageFlags[language.code]
              ) : (
                <i
                  className={classNames('far', {
                    'fa-dot-circle': currentLang === language.code,
                    'fa-circle': currentLang !== language.code,
                  })}
                />
              )}
            </span>
            <span>{t(`languageSwitcher.language.${language.code}`)}</span>
          </button>
        </li>
      ))}
    </ul>
  );
};

export default LanguageSwitcher;
