import sluggify from 'services/sluggify';

const Partners = {
  list: { as: '/partners', href: '/partners' },
  detail: (id: number, name?: string) => ({
    as: `/partners/${id}${name ? `-${sluggify(name)}` : ''}`,
    href: '/partners/[id]',
  }),
  index: { as: '/partners/discover', href: '/partners/discover' },
  discoverDetail: (id: number) => ({
    as: `/partners/discover/${id}`,
    href: '/partners/discover/[id]',
  }),
  create: { as: '/partners/admin', href: '/partners/admin' },
  update: (id: number) => ({
    as: `/partners/admin/${id}`,
    href: '/partners/admin/[id]',
  }),
};
export default Partners;
