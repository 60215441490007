// eslint-disable-next-line import/no-cycle
import HTTPKit from '../HTTPKit';

const amadeoUri = '/api/v1/amadeo';

export default {
  getSpendAndSavingsForCurrentYear: (memberId: string) => {
    const currentMemberId = memberId || 'current';
    const url = `${amadeoUri}/spend-and-savings-sum-for-current-year/${currentMemberId}`;
    return HTTPKit.get(url);
  },
  getSpendPerCategeoryForCurrentYear: (memberId: string) => {
    const currentMemberId = memberId || 'current';
    const url = `${amadeoUri}/spend-per-category-for-current-year/${currentMemberId}`;
    return HTTPKit.get(url);
  },
  getSuppliersSpendUsageForLastQuarter: (memberId: string) => {
    const currentMemberId = memberId || 'current';
    const url = `${amadeoUri}/supplier-spend-usage-for-last-quarter/${currentMemberId}`;
    return HTTPKit.get(url);
  },
  getTop5SuppliersWithMostSpendForCurrentYear: (memberId: string) => {
    const currentMemberId = memberId || 'current';
    const url = `${amadeoUri}/top-five-suppliers-with-most-spend-for-current-year/${currentMemberId}`;
    return HTTPKit.get(url);
  },
  getSpendAndSavingsSumForLastSixQuarters: (memberId: string) => {
    const currentMemberId = memberId || 'current';
    const url = `${amadeoUri}/spend-and-savings-sum-for-last-six-quarters/${currentMemberId}`;
    return HTTPKit.get(url);
  },
  getSpendAndSavingsPeriodsList: (memberId: string, page: number = 1) => {
    const currentMemberId = memberId || 'current';
    const url = `${amadeoUri}/spend-savings-periods-list/${currentMemberId}`;
    return HTTPKit.get(url, { page });
  },
  getSpendAndSavingsByPeriod: (
    memberId: string,
    year: string,
    quarter: string,
  ) => {
    const currentMemberId = memberId || 'current';
    const url = `${amadeoUri}/spend-savings-by-period-list/year/${year}/quarter/${quarter}/${currentMemberId}`;
    return HTTPKit.get(url);
  },
  getAvailableReports: () => {
    const url = `${amadeoUri}/available-reports`;
    return HTTPKit.get(url);
  },
  getReportsAdditionalInfo: () => {
    const url = `${amadeoUri}/reports-addtional-info`;
    return HTTPKit.get(url);
  },
};
