import React, { useContext } from 'react';

import UserContext from 'contexts/UserContext';

import ToggleButton, { toggleButtonType } from './ToggleButton';
import styles from '../DevControl.module.scss';

type ToggleType = toggleButtonType & {
  title: string;
  icon: string;
};

const createToggle = ({ title, icon, ...rest }: ToggleType): JSX.Element => (
  <div className="mb-2">
    <h2 className={styles.h2}>
      <i className={`text-gray-600 fas fa-${icon} mr-1`} /> {title}
    </h2>
    <ToggleButton key={rest.id} {...rest} />
  </div>
);

const ViewAsMember = () => {
  const { user, toggleViewAsStaff } = useContext(UserContext);
  return createToggle({
    label: 'Staff',
    isChecked: user.isStaff,
    handleChange: toggleViewAsStaff,
    id: 'toggle-view-as-staff',
    title: 'View As:',
    icon: 'user',
  });
};
export default ViewAsMember;
