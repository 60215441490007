import React, { useState } from 'react';

import Link from 'next/link';
import NotificationList from 'project/Notifications/components/NotificationList';
import { useTranslation } from 'react-i18next';
import RouteKit from 'services/RouteKit';

import styles from './NavMenu.module.scss';

const NotificationMenu = () => {
  const { t } = useTranslation();
  const [hasUnread, setHasUnread] = useState(false);

  return (
    <div
      className={`dropdown-toggle bg-transparent px-3 py-2 border-0
              dropdown ${styles.dropdown} ${styles.hideCaret}
              ${styles.noOutline}`}
    >
      <span>
        <i className="fas fa-bell" />
        {hasUnread && <div className={styles.notification} />}
      </span>
      <ul
        className={`list-unstyled dropdown-menu border-0 border-gray-50
                shadow-sm ${styles.dropdownMenu} ${styles.notificationMenu}
                dropdown-menu-right`}
      >
        <li className={styles.changeFontSize}>
          <NotificationList menu setUnread={setHasUnread} num={3} />
        </li>
        <li className={styles.changeFontSize}>
          <Link
            href={RouteKit.notifications.href}
            as={RouteKit.notifications.as}
            className={styles.notificatinButton}
          >
            {t('navigation.links.seeAllNotifications')}
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default NotificationMenu;
