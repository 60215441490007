import RouteKit from 'services/RouteKit';

const entityDetails = (
  type: string,
  verb?: string,
  title?: string,
  id: number = 0,
) => {
  let icon;
  let path;
  let iconText = '';

  switch (type) {
    case 'Event':
      icon = 'fa-calendar';
      path = RouteKit.events.detail(id, title);
      iconText = 'navigation.links.events';
      break;
    case 'News':
      icon = 'fa-newspaper';
      path = RouteKit.news.detail(id, title);
      iconText = 'navigation.links.news';
      break;
    case 'Wiki':
      icon = 'fa-graduation-cap';
      path = RouteKit.wiki.detail(id, title);
      iconText = 'wiki.articles.title';
      break;
    case 'Handbook':
      icon = 'fa-book';
      path = RouteKit.handbooks.detail(id);
      iconText = 'wiki.handbooks.title';
      break;
    case 'Post':
      icon = 'fa-comments';
      path = RouteKit.forum.thread({ id, options: {} });
      iconText = 'navigation.links.forum';
      break;
    case 'Campaign':
      icon = 'fa-tag';
      path = RouteKit.campaigns.detail(id, title);
      iconText = 'navigation.links.campaigns';
      break;
    case 'DirectAd':
      icon = 'fa-bullhorn';
      path = RouteKit.directads.detail(id);
      iconText = 'navigation.links.directads';
      break;
    case 'Partner':
      icon = 'fa-briefcase';
      path = RouteKit.partners.detail(id, title);
      iconText = 'navigation.links.partners';
      break;
    case 'Report':
      icon = 'fa-chart-line';
      path = RouteKit.reports.index;
      iconText = 'navigation.links.reports';
      break;
    case 'Service':
      icon = 'fa-concierge-bell';
      path = RouteKit.services.detail(id, title);
      iconText = 'navigation.links.services';
      break;
    default:
      icon = 'fa-bullhorn';
      path = RouteKit.index;
      break;
  }

  return {
    icon,
    notificationText: `notification.${verb}`,
    path,
    iconText,
  };
};
export default entityDetails;
