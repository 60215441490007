// eslint-disable-next-line import/no-cycle
import HTTPKit from '../HTTPKit';

const frontEndAdminEndpoints = {
  copyContent: (payload: {
    country: number;
    objId: number;
    model: string;
    app: 'marketplace' | 'writer' | 'wiki';
  }) => {
    const url = '/api/v1/copy-content/';
    return HTTPKit.post(url, payload);
  },
};
export default frontEndAdminEndpoints;
