// eslint-disable-next-line import/no-cycle
import HTTPKit from '../HTTPKit';

interface StaffCardListRequest {
  page?: number;
  title?: string;
  categories?: number[] | null;
}

const StaffEndpoints = {
  getDetail: (id: number) => {
    const url = `/api/v1/staff-cards/${id}/`;
    return HTTPKit.get(url);
  },
  getList: (queryOptions?: StaffCardListRequest) => {
    const url = '/api/v1/staff-cards/';
    return HTTPKit.get(url, queryOptions);
  },
};
export default StaffEndpoints;
