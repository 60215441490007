import React, { useContext, useState } from 'react';

import classNames from 'classnames';
import CenteredSpinner from 'components/Loading/CenteredSpinner';
import UserContext from 'contexts/UserContext';
import { FeatureFlagContext } from 'pages/_app';
import styles from 'project/Login/components/UserOnboarding/UserOnboarding.module.scss';
import { mutations as SWRMutateUser } from 'services/APIKit/SWR/user';
import { logError } from 'services/datadog';
import useSWRMutation from 'swr/mutation';

import OnboardingStep1 from './OnboardingModal.Step1';
import OnboardingStep2 from './OnboardingModal.Step2';
import OnboardingStep3 from './OnboardingModal.Step3';

const {
  patchUser: { key: MUTATE_USER, mutate: patchUser },
} = SWRMutateUser;

const OnboardingModal = (): JSX.Element => {
  const { trigger: patch, isMutating: isPatching } = useSWRMutation(
    MUTATE_USER,
    patchUser,
  );

  const [currentStep, setCurrentStep] = useState('1');

  const handleStep = (step: string) => {
    setCurrentStep(step);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const { user, refreshUser } = useContext(UserContext);
  const featureFlags = useContext(FeatureFlagContext);

  const availableIDs = featureFlags
    ?.filter(
      flag =>
        flag.available &&
        user.levels.some(level => flag.levels.includes(level.id)),
    )
    .map(flag => flag.feature);

  const handleOnboarding = async (): Promise<void> => {
    try {
      await patch({
        payload: { isOnboarded: true },
      });
      refreshUser();
    } catch (error) {
      logError({ error });
    }
  };

  return (
    <div className={styles.userOnboardingWrapper}>
      <div
        className={classNames(styles.userOnboarding, styles.fixed, 'card', {
          [styles.isMutating]: isPatching,
        })}
      >
        {isPatching && <CenteredSpinner />}
        {currentStep === '1' && <OnboardingStep1 handleRouting={handleStep} />}
        {currentStep === '2' && (
          <OnboardingStep2
            handleRouting={handleStep}
            featureFlags={availableIDs}
          />
        )}
        {currentStep === '3' && (
          <OnboardingStep3
            closeModal={handleOnboarding}
            featureFlags={availableIDs}
            isMutating={isPatching}
          />
        )}
      </div>
    </div>
  );
};

export default OnboardingModal;
