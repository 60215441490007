import '../assets/scss/bootstrap.scss';

import React, { createContext, Suspense, useEffect, useState } from 'react';

import { SpeedInsights } from '@vercel/speed-insights/next';
import DevControls from 'components/DevControls/DevControls';
import ErrorBoundary from 'components/ErrorBoundary';
import GlobalInfoMessage from 'components/GlobalInfoMessage/GlobalInfoMessage';
import Layout from 'components/Layout/Layout';
import Loading from 'components/Loading/Loading';
import UnsupportedBrowserMessage from 'components/UnsupportedBrowserMessage';
import UserContext from 'contexts/UserContext';
import App, { AppContext, AppInitialProps } from 'next/app';
import Router from 'next/router';
import APIKit from 'services/APIKit';
import HTTPKit from 'services/APIKit/HTTPKit';
import { getRefreshedTokens } from 'services/APIKit/interceptors/helpers';
import type { FeatureFlag, User } from 'services/APIKit/types';
import * as gtag from 'services/gtag';
import i18nextLocize, { locizeBackendOptions } from 'services/i18nextLocize';
import RouteKit from 'services/RouteKit';
import { isClient } from 'services/serverOrClient';
import { SWRConfig } from 'swr';

import DataDogInit, { logError, updateUserTracking } from '../services/datadog';
import isInternetExplorer from '../services/isInternetExplorer';

DataDogInit();
/**
 * These conf options for swr, will be used for all useSWR calls
 * unless overridden locally.
 *
 * Read more: https://swr.vercel.app/docs/options
 */
const second: number = 1000;
const minute: number = 60 * second;
const swrConf = {
  // provider: () => new Map(),
  revalidateOnFocus: false,
  suspense: true,
  // Time delay in milliseconds before refetching a cached query
  dedupingInterval: 1 * minute,
};
export const FeatureFlagContext = createContext<FeatureFlag[]>([]);

type InitialProps = AppInitialProps & {
  Component: any;
  isIE: boolean;
};

const VetfamilyApp = (props: InitialProps) => {
  const [user, setUser] = useState<User | null>(props.pageProps.user);
  const [featureFlags, setFeatureFlags] = useState<FeatureFlag[]>(
    props.pageProps.featureFlags,
  );
  const [isRouteChanging, setIsRouteChanging] = useState(false);
  const [isTranslationsLoaded, setIsTranslationsLoaded] = useState(false);

  const [viewSocialButton, setViewSocialButton] = useState(true);
  const [obfuscate, setObfuscate] = useState(false);
  const [overRideStaffStatus, setOverRideStaffStatus] = useState(false);

  const [previousURL, setPreviousURL] = useState('');

  const { Component, pageProps, isIE } = props;

  const { bearerToken, token, lang, defaultLocales } = pageProps;

  const initializeTranslations = async () => {
    await i18nextLocize(lang, defaultLocales);
    setIsTranslationsLoaded(true);
  };

  // Populate user context
  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const userContextValue = {
    token: bearerToken || token,
    userLang: lang,
    user: {
      ...(user || pageProps.user),
      isStaff: overRideStaffStatus ? false : user?.isStaff,
    },
    refreshUser: async () => {
      const { data } = await APIKit.users.getMe();
      setUser(data);
    },
    toggleViewAsStaff: () => {
      if (pageProps.user?.isStaff) {
        setOverRideStaffStatus(!overRideStaffStatus);
      }
    },
    toggleViewSocialButton: () => {
      setViewSocialButton(!viewSocialButton);
    },
    viewSocialButton,
    toggleObfuscation: () => {
      setObfuscate(!obfuscate);
    },
    obfuscate,
  };

  const showDevControls = user?.isStaff || user?.changeMember;

  const postActivityStream = (referral?: String) => {
    const url = Router.asPath;

    if (url !== previousURL) {
      setPreviousURL(url);
    }
    APIKit.stream.postActivityStream(url, referral || previousURL);
  };

  const onDone = () => {
    if (!Router.asPath.includes('/login')) {
      try {
        postActivityStream();
      } catch (error) {
        logError({ error });
      }
    }
  };
  const routeChangeComplete = (url: string) => {
    setIsRouteChanging(false);
    if (isClient()) {
      gtag.pageview(url);
    }
  };

  const routeChangeStart = () => {
    setIsRouteChanging(true);
  };

  const setFeatureflags = async () => {
    const { data: featureFlagData } = await APIKit.featureFlags.getList();
    setFeatureFlags(featureFlagData);
  };

  useEffect(() => {
    if (pageProps.user) {
      try {
        setFeatureflags();
      } catch (error) {
        console.error(error);
      }
    }
  }, [pageProps.user, user?.member?.id]);

  useEffect(() => {
    if (pageProps.user && pageProps.user?.id !== user?.id) {
      userContextValue.refreshUser();
    }
    return () => {
      setUser(null);
    };
  }, [pageProps.user?.id]);

  useEffect(() => {
    if (!isTranslationsLoaded) initializeTranslations();
    Router.events.on('routeChangeComplete', onDone);
    Router.events.on('routeChangeStart', routeChangeStart);
    Router.events.on('routeChangeComplete', routeChangeComplete);
    Router.events.on('routeChangeError', routeChangeComplete);

    return () => {
      Router.events.off('routeChangeComplete', onDone);
      Router.events.off('routeChangeStart', routeChangeStart);
      Router.events.off('routeChangeComplete', routeChangeComplete);
      Router.events.off('routeChangeError', routeChangeComplete);
    };
  }, []);

  useEffect(() => {
    if (!user) return;
    updateUserTracking(user);
  }, [user?.id, user?.firstName, user?.lastName, user?.email, user?.language]);

  if (isIE) {
    return <UnsupportedBrowserMessage />;
  }

  return isTranslationsLoaded ? (
    <>
      <Loading isRouteChanging={isRouteChanging} />
      <GlobalInfoMessage />
      <ErrorBoundary>
        <SWRConfig value={swrConf}>
          <UserContext.Provider value={userContextValue}>
            <FeatureFlagContext.Provider value={featureFlags}>
              <Layout hideMenu={Component.hideMenu}>
                {showDevControls && (
                  <Suspense>
                    <DevControls />
                  </Suspense>
                )}
                <Component {...pageProps} />
              </Layout>
            </FeatureFlagContext.Provider>
          </UserContext.Provider>
        </SWRConfig>
      </ErrorBoundary>
      <SpeedInsights />
    </>
  ) : (
    <div /> // We must return an element
  );
};

VetfamilyApp.getInitialProps = async (appContext: AppContext) => {
  // calls page's `getInitialProps` and fills `appProps.pageProps`
  // https://nextjs.org/docs/pages/api-reference/functions/get-initial-props

  const appProps = await App.getInitialProps(appContext);
  const headers = appContext.ctx.req?.headers;
  const isIE = isInternetExplorer(headers);

  let user: User | null = null;
  let featureFlags: FeatureFlag[] = [];

  if (appProps.pageProps.bearerToken || appProps.pageProps.token) {
    try {
      await HTTPKit.setAuthHeader(
        appProps.pageProps.bearerToken || appProps.pageProps.token,
      );
      let data;
      try {
        const response = await APIKit.users.getMe();
        data = response.data;
      } catch (error) {
        try {
          const newToken = await getRefreshedTokens(
            appContext.router.query.refreshToken ||
              appProps.pageProps.refreshToken,
          );
          await HTTPKit.setAuthHeader(newToken!);
          const response = await APIKit.users.getMe();
          data = response.data;
        } catch (err) {
          Router.push(RouteKit.login.index());
        }
      }

      user = data;
      featureFlags = appProps.pageProps.featureFlags ?? null;
      if (!appProps.pageProps.featureFlags) {
        const { data: featureFlagData } = await APIKit.featureFlags.getList();
        featureFlags = featureFlagData;
      }
    } catch (error) {
      console.error(error);
    }
  }

  const lang =
    user?.language ??
    appContext.router.query.language?.toString() ??
    appContext.router.locale ??
    'en';

  const defaultLocalesResponse = await fetch(
    locizeBackendOptions.loadPath
      .replace('{{projectId}}', locizeBackendOptions.projectId)
      .replace('{{version}}', locizeBackendOptions.version)
      .replace('{{lng}}', lang)
      .replace('{{ns}}', 'common'),
  );
  const defaultLocales = {
    [lang]: {
      common: await defaultLocalesResponse.json(),
    },
  };

  appProps.pageProps = {
    ...appProps.pageProps,
    lang: user?.language ?? lang,
    defaultLocales,
    user,
    featureFlags,
  };

  return { ...appProps, isIE };
};

export default VetfamilyApp;
