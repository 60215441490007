import React, { useContext } from 'react';

import classNames from 'classnames';
import LanguageSwitcher from 'components/LanguageSwitcher/LanguageSwitcher';
import UserContext from 'contexts/UserContext';
import Link from 'next/link';
import { FeatureFlagContext } from 'pages/_app';
import postprofileheaderstyles from 'project/Forum/components/PostProfileHeader/PostProfileHeader.module.scss';
import { useTranslation } from 'react-i18next';
import { Feature } from 'services/constants';
import RouteKit from 'services/RouteKit';
import { logout } from 'services/withAuth';

import styles from '../NavMenu.module.scss';
import NotificationMenu from '../NotificationMenu';

const UserMenu = () => {
  const { user } = useContext(UserContext);
  const { t } = useTranslation();
  const featureFlags = useContext(FeatureFlagContext);
  const filtered = {};
  const userMenuFlags = [Feature.SELF_ONBOARDING];
  featureFlags.forEach(el => {
    if (userMenuFlags.includes(el.feature) && el.available) {
      filtered[el.feature] = el;
    }
  });

  const selfOnboarding = filtered[Feature.SELF_ONBOARDING];
  const languageSelector = !!user?.member?.country.useLanguageSelector;

  const canManageUsers =
    (!user?.isShared &&
      user?.levels?.some(level => selfOnboarding?.levels.includes(level.id))) ||
    false;
  const isBEngaged =
    user?.member?.levels?.some(level => level.name === 'b-engaged') || false;

  return (
    <div>
      <div className="btn p-0">
        <NotificationMenu />
      </div>
      <div className="btn p-0">
        <div
          className={`dropdown-toggle bg-transparent p-0 py-2 border-0
                    dropdown ${styles.dropdown} ${styles.hideCaret}
                    ${styles.noOutline}`}
        >
          <p className={`mb-0 ${postprofileheaderstyles.initialsSmall}`}>
            {user.firstName?.[0]}
            {user.lastName?.[0]}
          </p>
          <ul
            className={`list-unstyled dropdown-menu border border-gray-50 shadow-sm ${styles.dropdownMenu} dropdown-menu-right`}
          >
            {languageSelector && (
              <li
                className={classNames(styles.dropdownItem, 'languageSwitcher')}
              >
                <span className={styles.userMenuLabel}>
                  <i className="fas fa-language mr-2" />
                  {t('languageSwitcher.menuItem.label')}
                </span>
                <LanguageSwitcher />
              </li>
            )}
            <li className={styles.dropdownItem}>
              <Link
                href={RouteKit.settings.me.href}
                as={RouteKit.settings.me.as}
                className={styles.userMenuLabel}
              >
                <i className="fas fa-user" />
                {t('navigation.links.settings.me')}
              </Link>
            </li>
            {canManageUsers && (
              <li className={styles.dropdownItem}>
                <Link
                  href={RouteKit.settings.users.href}
                  as={RouteKit.settings.users.as}
                  className={styles.userMenuLabel}
                >
                  <i className="fas fa-users" />
                  {t('navigation.links.settings.manageUsers')}
                </Link>
              </li>
            )}
            {canManageUsers && isBEngaged && (
              <li className={styles.dropdownItem}>
                <Link
                  href={RouteKit.settings.costumers.href}
                  as={RouteKit.settings.costumers.as}
                  className={styles.userMenuLabel}
                >
                  <i className="fas fa-id-badge" />
                  {t('navigation.links.settings.manageCostumers')}
                </Link>
              </li>
            )}
            <li className={styles.dropdownItem}>
              <button
                type="button"
                onClick={() => {
                  logout();
                }}
                className={classNames(styles.noOutline, styles.userMenuLabel)}
              >
                <i className="fas fa-sign-out-alt" />
                {t('navigation.logout')}
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default UserMenu;
