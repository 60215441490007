import { IncomingHttpHeaders } from 'http';

const isInternetExplorer = (
  headers: IncomingHttpHeaders | undefined,
): boolean => {
  const serverUserAgent: string | undefined = headers?.['user-agent'];
  const clientUserAgent: string =
    typeof window !== 'undefined' ? window.navigator.userAgent : '';
  const userAgent: string = serverUserAgent || clientUserAgent;

  return /Trident/g.test(userAgent);
};

export default isInternetExplorer;
