import React, { MouseEventHandler } from 'react';

import classNames from 'classnames';
import Link from 'next/link';
import { useTranslation } from 'react-i18next';

type LogoProps = {
  className?: string;
  width?: number;
  alt?: string;
  link?: { as?: string; href: string };
  onlyHeart?: boolean;
  onClick?: MouseEventHandler<HTMLImageElement>;
};
const LogoImg = ({ alt, onlyHeart, ...props }: LogoProps) => {
  const { t } = useTranslation();
  const altText = alt || t('company.name');
  return (
    <img
      {...props}
      src={`/img/svg/${
        onlyHeart ? 'logo_heart_red' : 'vetfamily_red_black'
      }.svg`}
      alt={altText}
    />
  );
};
const LogoLink = ({ className, link, ...props }: LogoProps) => (
  <Link {...link!} className={classNames({ [className as string]: className })}>
    <LogoImg {...props} />
  </Link>
);
const Logo = ({ link, ...props }: LogoProps) => {
  const Elm = link ? LogoLink : LogoImg;
  const elmProps = { ...props, link };
  return <Elm {...elmProps} />;
};

export default Logo;
