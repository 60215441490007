// eslint-disable-next-line import/no-cycle
import HTTPKit from '../HTTPKit';

const ReportEndpoints = {
  getList: (
    memberId: string,
    kind?: number | string,
    isSpendSavingsAvailable?: boolean,
    page: number = 1,
  ) => {
    const currentMemberId = memberId || 'current';
    const url = '/api/v1/reports/';
    return HTTPKit.get(url, {
      memberId: currentMemberId,
      page,
      kind,
      remove_qr: isSpendSavingsAvailable,
    });
  },
  shareReport: (id: number, email: string) => {
    const url = `/api/v1/reports/${id}/send-report/`;
    return HTTPKit.post(url, { email });
  },
  reportKind: {
    getList: () => {
      const url = '/api/v1/enums/report-kind/';
      return HTTPKit.get(url);
    },
  },
};
export default ReportEndpoints;
