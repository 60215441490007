// eslint-disable-next-line import/no-cycle
import HTTPKit from '../HTTPKit';

const ForumEndpoints = {
  getFilters: (following?: boolean) => {
    const url = '/api/v1/forum/categories/';
    return HTTPKit.get(url);
  },
  getForum: (
    page: number,
    category?: number | null,
    title?: string,
    order?: string,
    following?: boolean,
  ) => {
    const url = `/api/v1/forum/posts/`;
    const ordering = `-is_pinned, -${order}`;

    return HTTPKit.get(url, {
      page,
      category,
      title,
      ordering,
      following,
    });
  },
  getupdatedSubscriptions: () => {
    const url = '/api/v1/forum/subscriptions/updated/';
    return HTTPKit.get(url);
  },
  getForumCategory: (category: number) => {
    const url = `/api/v1/forum/categories/${category}/`;
    return HTTPKit.get(url);
  },
  getForumThread: (id: number) => {
    const url = `/api/v1/forum/posts/${id}/`;
    return HTTPKit.get(url);
  },
  getForumThreadReplies: (id: number) => {
    const url = `/api/v1/forum/posts/${id}/replies/`;
    return HTTPKit.get(url);
  },
  updateForumThread: (
    id: number,
    payload: Record<string, string | number | boolean | null> | FormData,
  ) => {
    const url = `/api/v1/forum/posts/${id}/`;
    return HTTPKit.patch(url, payload);
  },
  toggleForumThreadCloseState: (id: number, isClosed: boolean) => {
    // TODO January 26, 2021: Find way of calling updateForumThread
    const url = `/api/v1/forum/posts/${id}/`;
    return HTTPKit.patch(url, { isClosed });
  },
  toggleForumThreadPinState: (id: number, isPinned: boolean) => {
    // TODO January 26, 2021: Find way of calling updateForumThread
    const url = `/api/v1/forum/posts/${id}/`;
    return HTTPKit.patch(url, { isPinned });
  },
  subscribeToForumThread: (id: number) => {
    const url = '/api/v1/forum/subscriptions/';
    return HTTPKit.post(url, {
      post: id,
    });
  },
  // @ts-ignore
  unsubscribeToForumThread: async (id: number) => {
    // TODO January 26, 2021: Refactor how endpoint works
    const {
      data: { results: subscriptions },
    } = await HTTPKit.get('/api/v1/forum/subscriptions/');

    const subscriptionToDelete = subscriptions.find(
      (subscription: { post: number; id: number }) => subscription.post === id,
    );
    if (subscriptionToDelete) {
      const url = `/api/v1/forum/subscriptions/${subscriptionToDelete.id}/`;
      return HTTPKit.delete(url, {});
    }
  },
  deleteForumThread: (id: number) => {
    const url = `/api/v1/forum/posts/${id}/`;
    return HTTPKit.delete(url, {});
  },
  createForumThread: (formData: FormData) => {
    const url = '/api/v1/forum/posts/';
    return HTTPKit.post(url, formData);
  },
  createForumPost: (formData: FormData) => {
    const url = '/api/v1/forum/posts/';
    return HTTPKit.post(url, formData);
  },
  readForumPost: posts => {
    const url = '/api/v1/forum/read-post/';
    return HTTPKit.post(url, { unread: posts });
  },
};
export default ForumEndpoints;
