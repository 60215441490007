import React from 'react';

import Logo from 'components/Logo/Logo';
import NextStep from 'project/Login/components/UserOnboarding/NextStep/NextStep';
import styles from 'project/Login/components/UserOnboarding/UserOnboarding.module.scss';
import { useTranslation } from 'react-i18next';

interface Step1Props {
  handleRouting(step: string): void;
}

const Step1 = ({ handleRouting }: Step1Props): JSX.Element => {
  const { t } = useTranslation();

  const handleNextStep = () => {
    const nextStep = '2';
    handleRouting(nextStep);
  };

  return (
    <div className={styles.userOnboardingStep}>
      <Logo className={styles.logo} />
      <img src="/img/svg/delivery.svg" alt="" className={styles.illustration} />
      <h1 className={styles.mainTitle}>{t('onboarding.step5.heading')}</h1>
      <p className="font-weight-bold text-center mb-2">
        {t('onboarding.step5.subheading')}
      </p>
      <p className="text-gray-600 text-center mb-5">
        {t('onboarding.step5.description')}
      </p>
      <NextStep
        buttonLabel={t('onboarding.step5.buttonLabel')}
        isSubmitting={false}
        onSubmit={handleNextStep}
      />
    </div>
  );
};

export default Step1;
