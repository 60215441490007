import React from 'react';

import { useNProgress } from '@tanem/react-nprogress';

import styles from './Loading.module.scss';
import LoadingSpinner from './LoadingSpinner';

const Loading = ({ isRouteChanging }: { isRouteChanging: boolean }) => {
  const { animationDuration, isFinished, progress } = useNProgress({
    isAnimating: isRouteChanging,
  });

  return (
    <div
      style={{
        opacity: `${isFinished ? 0 : 1}`,
        transition: `opacity ${animationDuration}ms linear`,
      }}
      className={styles.container}
    >
      <div
        style={{
          marginLeft: `${(-1 + progress) * 100}%`,
          transition: `margin-left ${animationDuration}ms linear`,
        }}
        className={styles.barWrapper}
      >
        <div className={styles.bar} />
      </div>
      <LoadingSpinner />
    </div>
  );
};

export default Loading;
