import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import styles from './CenteredSpinner.module.scss';

type CenteredSpinnerProps = {
  color?: 'red' | 'green' | 'yellow' | 'blue' | 'white';
  displayBlock?: boolean;
  className?: string;
  message?: string;
  messages?: string[];
};

const Spinner = ({
  className,
  message,
  color = 'red',
}: CenteredSpinnerProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <>
      <div
        className={classNames(
          'spinner-border',
          'spinner-border-sm',
          styles.centeredSpinner,
          styles[color],
          {
            [className as string]: className,
          },
        )}
        role="status"
        data-test="spinner"
      >
        <span className="sr-only">{t('general.loading')}</span>
      </div>
      {message && (
        <p className={classNames(styles.message, styles[color])}>{message}</p>
      )}
    </>
  );
};

const BlockSpinner = ({
  className,
  message,
  color,
}: CenteredSpinnerProps): JSX.Element => (
  <div
    className={classNames(styles.displayBlock, {
      [className as string]: className,
    })}
  >
    <Spinner message={message} color={color} />
  </div>
);

const CenteredSpinner = ({
  className,
  color,
  displayBlock,
  message,
  messages,
}: CenteredSpinnerProps): JSX.Element => {
  const [msg, setMsg] = useState<string | undefined>(
    message || (messages && messages[0]) || undefined,
  );

  useEffect(() => {
    let interval: any = null;
    if (messages && messages.length > 0) {
      interval = setInterval(() => {
        setMsg(prev => {
          const currentIndex = messages.indexOf(prev as string);
          const nextIndex =
            currentIndex < messages.length - 1 ? currentIndex + 1 : 0;
          return messages[nextIndex];
        });
      }, 6000);
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [messages]);
  return displayBlock ? (
    <BlockSpinner className={className} message={msg} color={color} />
  ) : (
    <Spinner className={className} message={msg} color={color} />
  );
};

export default CenteredSpinner;
