export const COOKIES = {
  ACCESS_TOKEN: 'token',
};

export const Feature = {
  REPORTS: 0,
  PARTNERS: 1,
  NEWS: 2,
  EVENTS: 3,
  WIKI: 4,
  FORUM: 5,
  DIRECT_ADS: 6,
  CLINIC_FINDER: 7,
  SERVICES: 8,
  CAMPAIGNS: 9,
  CONTACT: 10,
  DIGEST: 11,
  SPEND_SAVINGS: 12,
  SELF_ONBOARDING: 13,
  HANDBOOKS: 14,
  COMMITMENTS: 15,
  VETDATA: 16,
  DISCOUNTS: 17,
};

export const KEY_CODES = {
  arrowUp: 38,
  arrowDown: 40,
  home: 36,
  end: 35,
  enter: 13,
  space: 32,
  escape: 27,
  tab: 9,
};

export default COOKIES;
