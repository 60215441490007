import React from 'react';

import Logo from 'components/Logo/Logo';
import { useTranslation } from 'react-i18next';

const UnsupportedBrowserMessage = () => {
  const { t } = useTranslation();
  return (
    <div
      className="container-fluid p-0"
      style={{
        paddingLeft: '60px',
        paddingRight: '60px',
      }}
    >
      <div className="mx-auto mt-5 Login_loginContainer__2SADK">
        <div className="p-5 bg-white shadow-2 rounded mb-5">
          <Logo className="d-block mx-auto mb-5" width={195} />
          <div className="text-center">
            <p className="mb-0 font-weight-bold text-gray-700">
              {t('general.broswerNotSupported.title')}
            </p>
            <p className="mb-3 text-gray-500">
              {t('general.broswerNotSupported.text')}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnsupportedBrowserMessage;
