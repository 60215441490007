import React from 'react';

import classNames from 'classnames';
import Logo from 'components/Logo/Logo';
import moment from 'moment';
import 'moment/min/locales.min';
import { useTranslation } from 'react-i18next';
import { EdgeDataInterface } from 'services/EdgeConfig';

import styles from './Maintenance.module.scss';

const Maintenance = ({
  deployDate,
  estimatedDownTimeInMinutes,
}: EdgeDataInterface) => {
  const { i18n, t } = useTranslation();
  moment.locale(i18n.language);

  const defaultBody =
    "We're updating the site, please come back in a bit and it will be all new and shiny!";
  let body = t('maintenance.body.generic', defaultBody);
  if (estimatedDownTimeInMinutes !== null) {
    const done = moment(deployDate).add(estimatedDownTimeInMinutes, 'minutes');
    // if the deploy date + estimeated downtime is in the past, we will show the generic message
    if (done.isAfter(moment())) {
      body = t('maintenance.body.withTime', {
        done: done.fromNow(),
      });
    }
  }

  return (
    <main className={styles.main}>
      <div className={classNames('container-fluid', styles.maintenance)}>
        <Logo width={150} className={styles.logo} />
        <h1>{t('maintenance.title', 'Under Maintenance')}</h1>
        <img src="/img/connect.png" alt="" className={styles.image} />
        <p className={styles.body}>{body}</p>
      </div>
    </main>
  );
};

export default Maintenance;
