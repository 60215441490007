// eslint-disable-next-line import/no-cycle
import HTTPKit from '../HTTPKit';

const DigestEndpoints = {
  unsubscribeFromLink: (id: string) => {
    const url = `/digest/unsubscribe/${id}/`;
    return HTTPKit.get(url);
  },
  toggleSubscription: (id?: number) => {
    const url = `/digest/toggleSubscrition/`;
    return HTTPKit.get(url, { id });
  },
  getList: (page: number = 1) => {
    const url = '/digest/past/';
    return HTTPKit.get(url, { page });
  },
  getDetail: (id: number | string) => {
    const url = `/digest/past/${id}/`;
    return HTTPKit.get(url, {}, undefined, 'blob');
  },
  getPreview: () => {
    const url = '/digest/preview/';
    return HTTPKit.get(url);
  },
  subscribers: {
    getList: (queryOptions?: { page?: number; email?: string }) => {
      const url = '/digest/subscribers/';
      return HTTPKit.get(url, queryOptions);
    },
    sendDigest: (id: number, filtered: boolean) => {
      const url = `/digest/send-digest/${id}/`;
      return HTTPKit.get(url, { filtered });
    },
  },
};

export default DigestEndpoints;
