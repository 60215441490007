import { FeatureFlag, User } from 'services/APIKit/types';
import { Feature } from 'services/constants';

import NavLinks from './Links';
import type { MenuLink, ProbableLinkType } from './types';

const getModifiedLinks = (availableIDs: number[]): MenuLink[] => {
  const deepClonedLinks = JSON.parse(JSON.stringify(NavLinks));
  const modifiedLinks = deepClonedLinks.map((link: MenuLink) => {
    if (link.kind === 'LINK') {
      const availabeLink = link.probableLinks?.find(
        (current: ProbableLinkType) => availableIDs.includes(current.id),
      );
      if (availabeLink) {
        link.id = availabeLink.id;
        link.path = availabeLink.path;
      }
    }
    return link;
  });
  return modifiedLinks;
};

// Returns a function that takes the item from
// the filter function as an argument
const navLinkFilter =
  ({ availableIDs, displayHandbooks, user }) =>
  (link: MenuLink) => {
    // only for staff
    if (link.kind === 'LINK') {
      if (link.staffOnly && !user.isStaff) return false;

      // staff should see handbooks even if there are no handbooks, but
      // users that are not staff should only see the menu item if there
      // is content
      if (link.id === Feature.HANDBOOKS && !displayHandbooks && !user.isStaff)
        return false;

      // if the link options are set to always on we should display it,
      // even if the link id is not included
      // if it is a dropdown link we will decide wether or not to display
      // it farther down
      if (!availableIDs.includes(link.id) && !link.alwaysOn) {
        return false;
      }
    } else {
      // Dropdown links contain child links, so we filter them equally,
      // and see if we have anything left to display in the dropdown
      link.links =
        link.links?.filter(
          navLinkFilter({ availableIDs, displayHandbooks, user }),
        ) || [];
      if (link.links?.length < 1) return false;
    }

    // if all else pass, let's show the menu link
    return true;
  };

// eslint-disable-next-line import/prefer-default-export
export const getNavMenuLinks = (
  featureFlags: FeatureFlag[],
  user: User,
  displayHandbooks: boolean,
  parseForMobile: boolean = false,
): MenuLink[] => {
  const availableIDs = featureFlags
    ?.filter(
      flag =>
        flag.displayInMenu &&
        flag.available &&
        user.levels?.some(level => flag.levels.includes(level.id)),
    )
    .map(flag => flag.feature);

  const modifiedLinks = getModifiedLinks(availableIDs);

  let navLinks = modifiedLinks.filter(
    navLinkFilter({ availableIDs, displayHandbooks, user }),
  );

  if (parseForMobile) {
    navLinks = navLinks
      .reduce(
        (acc, item) => {
          if (item.kind === 'LINK') {
            return [...acc, item];
          }
          if (item.links?.length) {
            return [...acc, ...item.links];
          }
          return acc;
        },
        <any>[],
      )
      .filter(Boolean);
  }

  return navLinks;
};
