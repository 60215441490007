import { UserContextType } from 'contexts/UserContext';
import { NextPageContext } from 'next';
import Router from 'next/router';
import { User } from 'services/APIKit/types';

import { Feature } from './constants';
import { isServer } from './serverOrClient';

export const truncateString = (str: string, maxLength: number): string =>
  str.length > maxLength ? str.substring(0, maxLength) : str;

export const markdownImageInjector = (data: string): string => {
  const injected = data.replace(
    /!\[\]\(\/media\/markdownx\//g,
    `![](${process.env.API_BASE_URL}/media/markdownx/`,
  );
  return injected;
};

export const getYouTubeIdFromLink = (
  url: string,
): string | string[] | null | undefined => {
  const youTubeId = url.match(
    /.*(youtu.be\/|v\/|e\/|u\/\w+\/|embed\/|v=)([^#&?]*).*/,
  );
  return youTubeId ? youTubeId[2] : null;
};

const capitalize = (str: string) => {
  const lower = str.toLowerCase();
  return str.charAt(0).toUpperCase() + lower.slice(1);
};

export const toCamelCase = (str: string) =>
  str
    .split(' ')
    .map((word, index) => {
      if (index === 0) return word.toLowerCase();

      return capitalize(word);
    })
    .join('');

export const redirectFromGetInitialProps = (
  route: { as: string; href: string },
  ctx: NextPageContext,
  error?: any,
) => {
  let routeAs = route.as;

  if (error) {
    routeAs = `/error?code=${error?.response?.status}&next=${route.as}`;
  }

  if (isServer()) {
    ctx.res?.writeHead(302, { Location: routeAs });
    ctx.res?.end();
  } else {
    Router.push(routeAs);
  }
};

export const redirectFromComponent = (
  route: { as: string; href: string },
  error?: any,
) => {
  let routeAs = route.as;

  if (error) {
    routeAs = `/error?code=${error?.response?.status}&next=${route.as}`;
  }

  Router.push(routeAs);
};

export const stripHtml = (html: string): string =>
  html.replace(/<\/?("[^"]*"|'[^']*'|[^>])*(>|$)/g, '');

export const renameKeys = (keysMap, obj) =>
  Object.keys(obj).reduce(
    (acc, key) => ({
      ...acc,
      ...{ [keysMap[key] || key]: obj[key] },
    }),
    {},
  );

export const featureDict = {
  0: 'pageTitle.reports',
  1: 'pageTitle.partners',
  2: 'pageTitle.news',
  3: 'pageTitle.events',
  4: 'wiki.articles.title',
  5: 'pageTitle.forum',
  6: 'directAds.title',
  7: 'Clinic Finder',
  8: 'pageTitle.services',
  9: 'pageTitle.campaigns',
  10: 'pageTitle.staff',
  11: 'pageTitle.digest',
  12: 'pageTitle.spendAndSavings',
  13: 'pageTitle.selfOnboarding',
  14: 'wiki.handbooks.title',
  15: 'commitments.tabs.title.index',
  16: 'navigation.links.vetData',
  17: 'general.label.discounts',
};

export const feature = {
  [Feature.REPORTS]: 'Report',
  [Feature.PARTNERS]: 'Partner',
  [Feature.NEWS]: 'News',
  [Feature.EVENTS]: 'Event',
  [Feature.WIKI]: 'Wiki',
  [Feature.FORUM]: 'Post',
  [Feature.DIRECT_ADS]: 'DirectAd',
  [Feature.CLINIC_FINDER]: 'Clinic Finder',
  [Feature.SERVICES]: 'Service',
  [Feature.CAMPAIGNS]: 'Campaign',
  [Feature.CONTACT]: 'Contact',
  [Feature.DIGEST]: 'Digest',
  [Feature.SPEND_SAVINGS]: 'Spend and Savings',
  [Feature.SELF_ONBOARDING]: 'Self Onboarding',
  [Feature.HANDBOOKS]: 'Handbooks',
  [Feature.COMMITMENTS]: 'Commitments',
  [Feature.VETDATA]: 'VetData',
  [Feature.DISCOUNTS]: 'Discounts',
};

export const statusEnum = [
  { id: 0, name: 'non_applicable' },
  { id: 1, name: 'draft' },
  { id: 2, name: 'published' },
  { id: 3, name: 'archived' },
];

export const rolesEnum = [
  { id: 14, name: 'role.owner' },
  { id: 15, name: 'role.manager' },
  { id: 16, name: 'role.contributor' },
  { id: 17, name: 'role.viewer' },
];

export const positionsEnum = [
  { id: 0, name: 'position.doctor' },
  { id: 1, name: 'position.nurse' },
  { id: 2, name: 'position.administrator' },
  { id: 3, name: 'position.other' },
];

export const fileTypeEnum = [
  { id: 0, name: 'general' },
  { id: 1, name: 'main_contract' },
  { id: 2, name: 'direct_contact' },
];

export const privacyPolicies = {
  nl: 'https://vetfamily.nl/privacy-beleid/',
  se: 'https://vetfamily.se/gdpr-policy/',
  es: 'https://vetfamily.es/politica-de-privacidad/',
  no: 'https://vetfamily.no/personvernpolicy/',
  fr: 'https://vetfamily.fr/politique-de-confidentialite/',
  de: 'https://vetfamily.de/datenschutzbestimmungen/',
  dk: 'https://vetfamily.dk/persondatapolitik/',
  en: 'https://vetfamily.com/privacy-policy/',
};

export const getLevelsAndRoles = (accessLevel: number[]) => {
  const userRoles: number[] = [];
  const memberLevels: number[] = [];
  accessLevel.forEach(level => {
    if (rolesEnum.some(role => role.id === level)) {
      userRoles.push(level);
    } else {
      memberLevels.push(level);
    }
  });
  return { userRoles, memberLevels };
};

export const includesLevel = (
  user: UserContextType['user'],
  accessLevel?: number[],
) => {
  let isAllowed: boolean | undefined = false;

  if (accessLevel) {
    const { userRoles, memberLevels } = getLevelsAndRoles(accessLevel);

    if (memberLevels.length) {
      isAllowed =
        user.member?.levels &&
        user.member?.levels.some(level => memberLevels.includes(level.id));
    } else {
      isAllowed = true;
    }

    if (isAllowed && userRoles.length) {
      isAllowed = user.levels.some(level => userRoles.includes(level.id));
    }
  }
  return isAllowed;
};

export const categoryName = (name: string) => `tag.${toCamelCase(name)}`;

export const getBossList = (users: User[]) => {
  const bossList = users
    .sort((a, b) => (a.role && b.role && a.role > b.role ? 1 : -1))
    .filter(u => u.role === rolesEnum[0].id || u.role === rolesEnum[1].id)
    .map(u => ({
      id: u.id,
      name: `${u.firstName} ${u.lastName}`,
    }));

  return bossList;
};

// Error styles. It is assigned to be used only inside Formik field.
export const getSelectFieldStyles = <T, E>(touched: T, errors: E) => {
  // the same colors for access levels field as for title
  const redColor = 'rgb(220 53 69 / 25%)';
  const greenColor = 'rgb(46 76 56 / 25%)';

  return {
    control: (provided, state) => ({
      ...provided,
      border: `1px solid ${
        // eslint-disable-next-line no-nested-ternary
        touched && errors ? '#dc3545' : state.isFocused ? '#5e9b73' : '#919191'
      }`,
      boxShadow: state.isFocused
        ? `0 0 0 0.2rem ${touched && errors ? redColor : greenColor}`
        : 'none',
      '&:hover': {
        borderColor: 'none',
      },
      '&:focus': {
        boxShadow: 'none',
      },
    }),
    placeholder: provided => ({
      ...provided,
      color: '#3b3b3b',
    }),
    dropdownIndicator: provided => ({
      ...provided,
      color: `${touched && errors ? '#dc3545' : '#3b3b3b'}`,
    }),
    indicatorSeparator: provided => ({
      ...provided,
      backgroundColor: '#919191',
    }),
  };
};

export const confirmDelete = () =>
  // eslint-disable-next-line no-alert
  window.confirm('Are you sure you wish to delete this item?');
