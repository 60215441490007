// eslint-disable-next-line import/no-cycle
import HTTPKit from '../HTTPKit';

const CampaignEndpoints = {
  getList: (query: {
    category?: string;
    year?: number;
    month?: number;
    partner?: number;
    status?: number;
    highlighted?: boolean;
  }) => {
    const url = '/api/v1/campaigns/';
    return HTTPKit.get(url, query);
  },
  getDetail: (id: number) => {
    const url = `/api/v1/campaigns/${id}/`;
    return HTTPKit.get(url);
  },
  getFilters: () => {
    const url = '/api/v1/campaigns/categories/';
    return HTTPKit.get(url);
  },
  createCampaign: (formData: FormData) => {
    const url = '/api/v1/campaigns/';
    return HTTPKit.post(url, formData);
  },
  updateCampaign: (id: number, formData: FormData) => {
    const url = `/api/v1/campaigns/${id}/`;
    return HTTPKit.patch(url, formData);
  },
  deleteCampaign: (id: number) => {
    const url = `/api/v1/campaigns/${id}/`;
    return HTTPKit.delete(url, {});
  },
  destroyFile: (id: number) => {
    const url = `/api/v1/campaign-files/${id}/`;
    return HTTPKit.delete(url, {});
  },
  updateFile: (id: number, formData: FormData) => {
    const url = `/api/v1/campaign-files/${id}/`;
    return HTTPKit.patch(url, formData);
  },
};
export default CampaignEndpoints;
