import React, { useContext } from 'react';

import classNames from 'classnames';
import { FeatureFlagContext } from 'pages/_app';
import { feature } from 'services/helpers';
import sluggify from 'services/sluggify';

import styles from '../DevControl.module.scss';

const FeatureFlags = () => {
  const featureFlags = useContext(FeatureFlagContext);

  return (
    <div className="pt-1">
      <h2 className={styles.h2}>
        <i className="text-gray-600 fas fa-flag mr-1" /> Features Available:
      </h2>

      <ul className={styles.featuresList}>
        {featureFlags?.map(flag => {
          const id = sluggify(`feature-${flag.feature}`);
          const icon = flag.available ? 'fa-check-circle' : 'fa-times-circle';
          return (
            <li
              key={id}
              className={classNames(styles.featureItem, {
                [styles.inActive]: !flag.available,
              })}
            >
              <i className={classNames('far', icon)} /> {feature[flag.feature]}
            </li>
          );
        })}
      </ul>
    </div>
  );
};
export default FeatureFlags;
